import { useCreateCourseMutation } from "entities/course/coursesApi";
import { useGetSubsectionsQuery } from "entities/subsection/subsectionApi";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface CreateCoursePageProps {}

const CreateCoursePage: React.FC<CreateCoursePageProps> = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number | "">("");
  const [subsectionId, setSubsectionId] = useState<string>("");

  const { data: subsections, isLoading, error } = useGetSubsectionsQuery();
  const [createCourse] = useCreateCourseMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await createCourse({
        description,
        price: Number(price),
        title,
        subsection_id: Number(subsectionId),
      }).unwrap();
      toast.success("Курс успешно создан!");
    } catch (error) {
      toast.error("Не удалось создать курс.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <form
        onSubmit={handleSubmit}
        className="bg-white dark:bg-gray-800 shadow-lg rounded-xl px-8 pt-6 pb-8 w-full max-w-lg"
      >
        <h2 className="text-3xl font-extrabold mb-6 text-gray-800 dark:text-white">
          Создание нового курса
        </h2>

        <div className="mb-6">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-2"
            htmlFor="title"
          >
            Название курса
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow-md appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-2"
            htmlFor="description"
          >
            Описание
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow-md appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-2"
            htmlFor="price"
          >
            Цена
          </label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) =>
              setPrice(e.target.value ? parseFloat(e.target.value) : "")
            }
            className="shadow-md appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-semibold mb-2"
            htmlFor="subsection_id"
          >
            Подраздел
          </label>
          {isLoading ? (
            <div className="space-y-4">
              <div className="h-10 bg-gray-300 dark:bg-gray-700 animate-pulse"></div>
              <div className="h-10 bg-gray-300 dark:bg-gray-700 animate-pulse"></div>
              <div className="h-10 bg-gray-300 dark:bg-gray-700 animate-pulse"></div>
            </div>
          ) : error ? (
            <div className="text-red-500">Не удалось загрузить подразделы.</div>
          ) : (
            <select
              id="subsection_id"
              value={subsectionId}
              onChange={(e) => setSubsectionId(e.target.value)}
              className="shadow-md appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            >
              <option value="">Выберите подраздел</option>
              {subsections &&
                subsections.map((subsection) => (
                  <option key={subsection.id} value={subsection.id}>
                    {subsection.name}
                  </option>
                ))}
            </select>
          )}
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-blue-600 dark:hover:bg-blue-800"
          >
            Создать курс
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCoursePage;
