import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  GetStatsParams,
  GetStatsResponseItem,
  ShowProfileResponse,
} from "entities/user/types";

const baseUrl = `${process.env.REACT_APP_API}/profile`; // Замените на ваш базовый URL

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    showMyProfile: builder.query<ShowProfileResponse, void>({
      query: () => `/users/my`,
    }),
    showProfile: builder.query<ShowProfileResponse, number>({
      query: (userId) => ({
        url: `/${userId}`,
      }),
    }),
    getStats: builder.query<GetStatsResponseItem[], GetStatsParams>({
      query: ({ dateFilter, startDate, endDate }) => ({
        url: `/stats/get`,
        params: {
          date_filter: dateFilter,
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
  }),
});

export const { useShowMyProfileQuery, useShowProfileQuery, useGetStatsQuery } =
  profileApi;
