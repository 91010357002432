import React from "react";
import { NavLink } from "react-router-dom";

const SubHeader = () => {
  return (
    <div className="bg-white dark:bg-gray-800 p-4">
      <nav className="flex justify-center space-x-4">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `text-gray-700 dark:text-gray-200 
                        ${
                          isActive
                            ? "font-bold text-blue-500 dark:text-blue-400"
                            : "hover:text-blue-500 dark:hover:text-blue-400"
                        } 
                        transition-colors`
          }
        >
          Нейросети
        </NavLink>
        <NavLink
          to="/images"
          className={({ isActive }) =>
            `text-gray-700 dark:text-gray-200 
                        ${
                          isActive
                            ? "font-bold text-blue-500 dark:text-blue-400"
                            : "hover:text-blue-500 dark:hover:text-blue-400"
                        } 
                        transition-colors`
          }
        >
          Изображения
        </NavLink>
        <NavLink
          to="/digital-products"
          className={({ isActive }) =>
            `text-gray-700 dark:text-gray-200 
                        ${
                          isActive
                            ? "font-bold text-blue-500 dark:text-blue-400"
                            : "hover:text-blue-500 dark:hover:text-blue-400"
                        } 
                        transition-colors`
          }
        >
          Цифровые товары
        </NavLink>
        <NavLink
          to="/courses"
          className={({ isActive }) =>
            `text-gray-700 dark:text-gray-200 
                        ${
                          isActive
                            ? "font-bold text-blue-500 dark:text-blue-400"
                            : "hover:text-blue-500 dark:hover:text-blue-400"
                        } 
                        transition-colors`
          }
        >
          Видеокурсы
        </NavLink>
      </nav>
    </div>
  );
};

export default SubHeader;
