// entities/video/videosApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  CreateVideoBody,
  GetVideosListParams,
  Video,
} from "entities/video-course/video-course-types";

const baseUrl = `${process.env.REACT_APP_API}/videos`; // Замените на ваш базовый URL

export const videosApi = createApi({
  reducerPath: "videosApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createVideo: builder.mutation<{ id: number }, CreateVideoBody>({
      query: ({ course_id, title, video, is_demo }) => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("video", video);
        formData.append("course_id", course_id);
        formData.append("is_demo", is_demo);
        return {
          url: `/create`,
          method: "POST",
          body: formData,
        };
      },
    }),
    streamVideo: builder.query<Blob, string>({
      query: (fileName) => ({
        url: `stream/${fileName}/handler`,
        responseHandler: (response) => response.blob(),
      }),
    }),
    showVideo: builder.query<Video, number>({
      query: (id) => `${id}`,
    }),
    getVideosList: builder.query<Video[], GetVideosListParams>({
      query: (params) => ({
        params,
        url: "",
      }),
    }),
  }),
});

export const {
  useCreateVideoMutation,
  useShowVideoQuery,
  useGetVideosListQuery,
  useStreamVideoQuery,
} = videosApi;
