import { useShowVideoQuery } from "entities/video-course/videoc-course-api";
import { useParams } from "react-router-dom";
import VideoPlayer from "entities/video-course/ui/VideoPlayer";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";
import { useGetMyPurchaseByProductQuery } from "entities/purchase/purchaseApi";
import { useAppSelector } from "app/store/hooks";
import { selectUser } from "features/auth/slice/authSlice";

const baseUrl = `${process.env.REACT_APP_API}/api`; // Replace with your base URL

const formatDate = (dateString: Date) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const VideoPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: video, error, isLoading } = useShowVideoQuery(Number(id));
  const me = useAppSelector(selectUser);
  const {
    data: myPurchase,
    isLoading: myPurchaseLoading,
    error: purchaseError,
  } = useGetMyPurchaseByProductQuery(
    {
      productType: "course",
      productId: Number(video && video?.course_id),
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  if (isLoading || myPurchaseLoading) return <Loading />;

  // Check if the user is the author of the course
  const isAuthor = me?.id === video?.course?.user_id;
  console.log(!myPurchase || myPurchase.status !== "completed");

  if (
    !isAuthor &&
    (!myPurchase || myPurchase.status !== "completed") &&
    !video?.is_demo
  ) {
    return (
      <p className="text-red-500 dark:text-red-400">
        Доступ к курсу только после покупки.
      </p>
    );
  }

  if (error) {
    toast.error("Ошибка загрузки видео. Пожалуйста, попробуйте снова.");
  }

  if (isLoading) {
    return (
      <div className="container mx-auto p-6 min-h-[80vh] flex items-center justify-center bg-gradient-to-b from-gray-100 via-gray-200 to-gray-300 dark:from-gray-800 dark:via-gray-900 dark:to-black">
        <div className="animate-pulse max-w-3xl w-full bg-gray-300 dark:bg-gray-700 rounded-lg shadow-xl p-8">
          <div className="h-10 bg-gray-400 rounded mb-4"></div>
          <div className="aspect-w-16 aspect-h-9 bg-gray-400 rounded-lg mb-6"></div>
          <div className="space-y-4">
            <div className="h-6 bg-gray-400 rounded"></div>
            <div className="h-6 bg-gray-400 rounded"></div>
            <div className="h-6 bg-gray-400 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !video) {
    return (
      <p className="text-center text-red-500 dark:text-red-400">
        Ошибка загрузки видео.
      </p>
    );
  }

  return (
    <div className="container mx-auto p-6 min-h-[80vh] flex items-center justify-center bg-gradient-to-b from-gray-100 via-gray-200 to-gray-300 dark:from-gray-800 dark:via-gray-900 dark:to-black">
      <div className="max-w-3xl w-full bg-white dark:bg-gray-900 rounded-lg shadow-xl p-8">
        <h3 className="text-4xl font-bold text-center text-gray-800 dark:text-gray-100 mb-6">
          {video.title}
        </h3>

        {/* Video Player */}
        <VideoPlayer
          baseUrl={baseUrl}
          token={localStorage.authToken}
          video={video}
        />

        {/* Video Details */}
        <div className="text-center space-y-4">
          <p className="text-gray-600 dark:text-gray-400 text-lg">
            <strong className="text-gray-800 dark:text-gray-200">
              Дата добавления:
            </strong>{" "}
            {formatDate(video.created_at)}
          </p>
          <p className="text-gray-600 dark:text-gray-400 text-lg">
            <strong className="text-gray-800 dark:text-gray-200">
              Последнее обновление:
            </strong>{" "}
            {formatDate(video.updated_at)}
          </p>
          <p className="text-lg">
            <strong className="text-gray-800 dark:text-gray-200">
              Тип видео:
            </strong>{" "}
            <span
              className={`${
                video.is_demo
                  ? "bg-yellow-500 text-white"
                  : "bg-green-500 text-white"
              } px-3 py-1 rounded-full font-semibold`}
            >
              {video.is_demo ? "Демо" : "Полная версия"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
