import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-center mt-4 gap-2">
      <button
        disabled={currentPage === 1}
        onClick={() => onPageChange(1)}
        className="px-3 py-1 rounded text-blue-500 dark:text-blue-300 hover:underline disabled:text-gray-400"
      >
        <FaAngleDoubleLeft />
      </button>

      <button
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        className="px-3 py-1 rounded text-blue-500 dark:text-blue-300 hover:underline disabled:text-gray-400"
      >
        <FaAngleLeft />
      </button>

      {pageNumbers.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`px-3 py-1 rounded ${
            page === currentPage
              ? "bg-blue-500 text-white dark:bg-blue-700"
              : "text-blue-500 dark:text-blue-300 hover:bg-gray-200 dark:hover:bg-gray-700"
          }`}
        >
          {page}
        </button>
      ))}

      <button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        className="px-3 py-1 rounded text-blue-500 dark:text-blue-300 hover:underline disabled:text-gray-400"
      >
        <FaAngleRight />
      </button>

      <button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
        className="px-3 py-1 rounded text-blue-500 dark:text-blue-300 hover:underline disabled:text-gray-400"
      >
        <FaAngleDoubleRight />
      </button>
    </div>
  );
};

export default Pagination;