// ProtectedRoute.tsx
import { Navigate } from "react-router-dom";
import { useAppSelector } from "app/store/hooks";
import { selectToken } from "features/auth/slice/authSlice";

interface ProtectedRouteProps {
  children: JSX.Element;
  isAuthRoute?: boolean; // Опциональный пропс для определения типа маршрута
}

const ProtectedRoute = ({ children, isAuthRoute }: ProtectedRouteProps) => {
  const token = useAppSelector(selectToken);

  // If the user is authenticated and trying to access auth routes, redirect to home
  if (token && isAuthRoute) {
    return <Navigate to="/" replace />;
  }

  // If the user is not authenticated and trying to access protected routes, redirect to login
  if (!token && !isAuthRoute) {
    return <Navigate to="/auth/login" replace />;
  }

  return children; // If the route is accessible, render the child components
};

export default ProtectedRoute;
