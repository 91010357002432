import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";

const baseUrl = `${process.env.REACT_APP_API}/balance`; // Замените на ваш базовый URL

// Создаем API для работы с балансом
export const balanceApi = createApi({
  reducerPath: "balanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createOrder: builder.mutation<{ id: string }, { amount: string }>({
      query: (data) => ({
        url: "/order",
        method: "POST",
        body: data,
      }),
    }),
    captureOrder: builder.mutation<void, { orderId: string }>({
      query: (data) => ({
        url: "/capture",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useCreateOrderMutation, useCaptureOrderMutation } = balanceApi;
