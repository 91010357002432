import { useAppDispatch } from "app/store/hooks";
import { useLoginMutation } from "features/auth/authApi";
import { setToken, setUser } from "features/auth/slice/authSlice";
import { useState, useEffect } from "react";
import { FaGoogle, FaFacebookF, FaTwitter, FaVk } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {};

const LoginPage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login] = useLoginMutation();
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const user = query.get("user");
    const error = query.get("error");

    if (error) {
      toast.error(decodeURIComponent(error));
    }

    if (token) {
      localStorage.setItem("authToken", token);
      dispatch(setToken(token));
      if (user) {
        localStorage.setItem("user", user);
        dispatch(setUser(JSON.parse(user)));
      }

      navigate("/");
    }
  }, [navigate, dispatch]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login({ email, password }).unwrap();

      localStorage.setItem("authToken", data.token);
      dispatch(setToken(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch(setUser(data.user));

      toast.success("Успешный вход в систему!");
      navigate("/");
    } catch (error: any) {
      if (error.data && error.data.errors) {
        const errorMessages = Object.values(
          error.data.errors
        ).flat() as string[];
        errorMessages.forEach((msg) => toast.error(msg));
      } else {
        toast.error(error?.data?.message || "Ошибка авторизации");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-4 mt-5">
      <h1 className="text-4xl font-bold mb-8">Вход в систему</h1>

      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
        <form onSubmit={handleLogin} className="space-y-4">
          <h2 className="text-2xl font-semibold text-center mb-4">
            Вход с Email
          </h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
            required
          />
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full ${
              isLoading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
            } text-white py-2 rounded transition duration-300`}
          >
            {isLoading ? "Загрузка..." : "Войти"}
          </button>
        </form>

        <div className="flex items-center justify-center my-4">
          <span className="h-px w-full bg-gray-300 dark:bg-gray-600" />
          <span className="px-2 text-gray-500 dark:text-gray-400 text-sm">
            или
          </span>
          <span className="h-px w-full bg-gray-300 dark:bg-gray-600" />
        </div>

        <div className="space-y-2">
          <a
            href={`${process.env.REACT_APP_API}/auth/google/redirect`}
            className="flex items-center justify-center bg-red-500 hover:bg-red-600 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaGoogle className="mr-2" /> Войти с Google
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/facebook/redirect`}
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaFacebookF className="mr-2" /> Войти с Facebook
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/twitter/redirect`}
            className="flex items-center justify-center bg-blue-400 hover:bg-blue-500 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaTwitter className="mr-2" /> Войти с Twitter
          </a>
          <a
            href={`${process.env.REACT_APP_API}/auth/vk/redirect`}
            className="flex items-center justify-center bg-blue-700 hover:bg-blue-800 text-white py-2 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
          >
            <FaVk className="mr-2" /> Войти с VK
          </a>
        </div>

        <p className="text-center text-sm text-gray-500 dark:text-gray-400 mt-6">
          Еще нет аккаунта?{" "}
          <Link
            to="/auth/register"
            className="text-blue-500 hover:underline dark:text-blue-400"
          >
            Зарегистрироваться
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
