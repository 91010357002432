import { useAppDispatch } from "app/store/hooks";
import {
  setIs18Plus,
  setPriceMax,
  setPriceMin,
  setTagFilter,
  setAllowDuplicates, // Импортируем новое действие
  setCaseSensitive, // Импортируем новое действие
} from "entities/image/imagesSlice";
import { useState } from "react";

type Props = {};

const Filter = (props: Props) => {
  const [tagStateFilter, setTagStateFilter] = useState("");
  const [is18PlusState, setIs18PlusState] = useState(false);
  const [priceMinState, setPriceMinState] = useState("");
  const [priceMaxState, setPriceMaxState] = useState("");
  const dispatch = useAppDispatch();

  // Массив для хранения тегов
  const [tags, setTags] = useState<string[]>([]);

  // Состояния для чекбоксов
  const [allowDuplicates, setAllowDuplicatesState] = useState(false);
  const [caseSensitive, setCaseSensitiveState] = useState(false);

  const handleClick = () => {
    const tagToAdd = tagStateFilter.trim();

    // Проверяем условия добавления тега
    if (
      tagToAdd &&
      (!allowDuplicates ||
        !tags.some((tag) =>
          caseSensitive
            ? tag === tagToAdd
            : tag.toLowerCase() === tagToAdd.toLowerCase()
        ))
    ) {
      setTags([...tags, tagToAdd]); // Добавляем тег
      dispatch(setTagFilter(tagToAdd)); // Диспачим добавленный тег
    }

    // Диспачим состояния чекбоксов
    dispatch(setIs18Plus(is18PlusState));
    dispatch(setPriceMin(Number(priceMinState)));
    dispatch(setPriceMax(Number(priceMaxState)));
    dispatch(setAllowDuplicates(allowDuplicates)); // Диспачим разрешение дублирования
    dispatch(setCaseSensitive(caseSensitive)); // Диспачим учет регистра
  };

  const handleReset = () => {
    setTagStateFilter("");
    setTags([]); // Очищаем массив тегов
    setIs18PlusState(false);
    setPriceMinState("");
    setPriceMaxState("");
    setAllowDuplicatesState(false); // Сбрасываем состояние чекбокса
    setCaseSensitiveState(false); // Сбрасываем состояние чекбокса
    dispatch(setTagFilter(""));
    dispatch(setIs18Plus(undefined));
    dispatch(setPriceMin(0));
    dispatch(setPriceMax(0));
    dispatch(setAllowDuplicates(false)); // Сбрасываем в Redux
    dispatch(setCaseSensitive(false)); // Сбрасываем в Redux
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold">Фильтры</h2>
      <div className="grid grid-cols-1 gap-4 mt-2">
        <input
          type="text"
          placeholder="Тег"
          value={tagStateFilter}
          onChange={(e) => setTagStateFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded dark:border-gray-600"
        />

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={allowDuplicates}
            onChange={(e) => setAllowDuplicatesState(e.target.checked)}
            className="mr-2"
          />
          <label>Разрешить дублирование тегов</label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={caseSensitive}
            onChange={(e) => setCaseSensitiveState(e.target.checked)}
            className="mr-2"
          />
          <label>Учитывать регистр</label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={is18PlusState}
            onChange={(e) => setIs18PlusState(e.target.checked)}
            className="mr-2"
          />
          <label>18+ Контент</label>
        </div>

        <div className="flex">
          <input
            type="number"
            placeholder="Мин. цена"
            value={priceMinState}
            onChange={(e) => setPriceMinState(e.target.value)}
            className="p-2 border border-gray-300 rounded dark:border-gray-600 w-1/2 mr-2"
          />
          <input
            type="number"
            placeholder="Макс. цена"
            value={priceMaxState}
            onChange={(e) => setPriceMaxState(e.target.value)}
            className="p-2 border border-gray-300 rounded dark:border-gray-600 w-1/2"
          />
        </div>

        <div className="flex gap-4">
          <button
            onClick={handleClick}
            className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800"
          >
            Применить Фильтры
          </button>

          <button
            onClick={handleReset}
            className="mt-4 p-2 bg-gray-500 text-white rounded hover:bg-gray-600 dark:bg-gray-700 dark:hover:bg-gray-800"
          >
            Сбросить Фильтры
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
