import React from "react";

type Props = {};

const Loading = (props: Props) => {
  return (
    <div className="flex items-center justify-center h-screen dark:bg-gray-900">
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 border-t-2 mr-2"></div>
      <span className="text-lg text-gray-700 dark:text-gray-300">
        Загрузка...
      </span>
    </div>
  );
};

export default Loading;
