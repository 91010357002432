import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { ChatWithName } from "entities/chat/chatTypes";
import { SendMessageBodyMessagesItem } from "features/openrouter/openrouterTypes";

// Define a type for the slice state
interface OpenrouterState {
  chatInput: string;
  messages: string[];
  sendedMessages: SendMessageBodyMessagesItem[];
  isChatCreated: boolean;
  activeChatId: number;
  chats: ChatWithName[]; 
} 

// Define the initial state using that type
const initialState: OpenrouterState = {
  chatInput: "",
  messages: [] as string[],
  sendedMessages: [] as SendMessageBodyMessagesItem[],
  isChatCreated: false,
  activeChatId: -1,
  chats: [] as ChatWithName[],
}; 

export const openrouterSlice = createSlice({
  name: "openrouter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setChatInput: (state, action: PayloadAction<string>) => {
      state.chatInput = action.payload;
    },
    setMessages: (state, action: PayloadAction<string[]>) => {
      state.messages = action.payload;
    },
    setSendedMessages: (
      state,
      action: PayloadAction<SendMessageBodyMessagesItem[]>
    ) => {
      state.sendedMessages = action.payload;
    },
    setIsChatCreated: (state, action: PayloadAction<boolean>) => {
      state.isChatCreated = action.payload;
    },
    setActiveChatId: (state, action: PayloadAction<number>) => {
      state.activeChatId = action.payload;
    },
    setChats: (state, action: PayloadAction<ChatWithName[]>) => {
      // Sort messages in each chat by created_at date
      state.chats = action.payload;
    },
    appendSendedMessages: (
      state,
      action: PayloadAction<SendMessageBodyMessagesItem[]>
    ) => {
      state.sendedMessages.push(...action.payload);
    },
    appendMessages: (state, action: PayloadAction<string[]>) => {
      state.messages.push(...action.payload);
    },
    clearState: (state) => {
      state.activeChatId = -1;
      state.isChatCreated = false;
      state.messages = [];
      state.sendedMessages = [];
    },
  },
});

export const {
  clearState,
  setChatInput,
  setIsChatCreated,
  setMessages,
  setSendedMessages,
  setActiveChatId,
  setChats,
  appendMessages,
  appendSendedMessages,
} = openrouterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectChatInput = (state: RootState) => state.openrouter.chatInput;
export const selectIsChatCreated = (state: RootState) =>
  state.openrouter.isChatCreated;
export const selectMessages = (state: RootState) => state.openrouter.messages;
export const selectSendedMessages = (state: RootState) =>
  state.openrouter.sendedMessages;
export const selectActiveChatId = (state: RootState) =>
  state.openrouter.activeChatId;
export const selectChats = (state: RootState) => state.openrouter.chats;

export default openrouterSlice.reducer;
