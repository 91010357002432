import { useAppSelector } from "app/store/hooks";
import { useShowCourseQuery } from "entities/course/coursesApi";
import { useCreateVideoMutation } from "entities/video-course/videoc-course-api";
import { selectUser } from "features/auth/slice/authSlice";
import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "shared/ui/Loading";

type Props = {};

const CreateVideoCoursePage: React.FC<Props> = () => {
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState<File | null>(null);
  const [isDemo, setIsDemo] = useState(false); // State for is_demo
  const [createVideo, { isLoading: isCreating }] = useCreateVideoMutation(); // Destructure isLoading
  const { id } = useParams();
  const navigate = useNavigate();
  const me = useAppSelector(selectUser);
  const { data, isLoading, error } = useShowCourseQuery(Number(id));

  useEffect(() => {
    // Проверяем, является ли пользователь автором курса
    if (me?.id !== data?.user_id) {
      toast.error("You are not the author of this course.");
      navigate("/courses"); // Перенаправляем на страницу курсов
    }
  }, [me, navigate, data]);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title || !video || !id) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const createdVideo = await createVideo({
        title,
        video,
        course_id: id,
        is_demo: isDemo ? "1" : "0", // Convert boolean to "1" or "0"
      }).unwrap();

      setTitle("");
      setVideo(null);
      setIsDemo(false);

      toast.success(
        <div>
          Video course created successfully.{" "}
          <Link
            to={`/videos/${createdVideo.id}`}
            className="text-blue-400 underline"
          >
            View Video
          </Link>
        </div>
      );
    } catch (error: any) {
      console.log(error);
      if (error.data?.errors) {
        const errors = error.data.errors;
        Object.keys(errors).forEach((field) => {
          errors[field].forEach((errorMessage: string) => {
            toast.error(`${field}: ${errorMessage}`);
          });
        });
      } else {
        const errorMsg = error.data?.message || "An error occurred";
        toast.error(errorMsg);
      }
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className="flex justify-center items-center min-h-[80vh] bg-gray-100 dark:bg-gray-900">
      <ToastContainer />
      <div className="w-full max-w-lg p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 text-center">
          Create New Video Course
        </h2>

        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
              placeholder="Enter video title"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Video File
            </label>
            <input
              type="file"
              accept="video/*"
              onChange={(e) => setVideo(e.target.files?.[0] || null)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Demo Video
            </label>
            <input
              type="checkbox"
              checked={isDemo}
              onChange={(e) => setIsDemo(e.target.checked)}
              className="mr-2"
            />
            <span className="text-gray-700 dark:text-gray-300">
              Mark as demo
            </span>
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 dark:bg-blue-500 text-white rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none"
            disabled={isCreating} // Disable button while creating video
          >
            {isCreating ? "Creating..." : "Create Video Course"}{" "}
            {/* Change text to indicate loading */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateVideoCoursePage;
