import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { logout, selectToken, selectUser } from "features/auth/slice/authSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Для навигации
import Avatar from "shared/ui/Avatar";
import Search from "shared/ui/Search";
import ToggleTheme from "shared/ui/ToggleTheme";

const Header = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const links: {
    to: string;
    text: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  }[] = [
    {
      to: "/dashboard",
      text: "Мой профиль",
    },
    {
      to: "/balance",
      text: "Пополнить баланс",
    },
    {
      to: "/notifications",
      text: "Уведомления",
    },
    {
      to: "/points-store",
      text: "Магазин очков",
    },
    {
      to: "/my-cards",
      text: "Мои карты",
    },
    {
      to: "dashboard/purchases-history",
      text: "История покупок",
    },
    {
      to: "/assumptions-stats",
      text: "Статистика по предложениям",
    },
    {
      to: "/settings",
      text: "Настройки",
    },
    {
      to: "/",
      text: "Выход",
      onClick: () => dispatch(logout()),
    },
  ];

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
      document.documentElement.classList.toggle("dark", storedTheme === "dark");
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.documentElement.classList.toggle("dark");
    localStorage.setItem("theme", newTheme); // Сохраните новую тему в localStorage
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.currentTarget as HTMLFormElement; // Type assertion
    const searchTerm = (target.elements.namedItem("search") as HTMLInputElement)
      .value; // Explicitly type the element
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <header className="z-50 flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow-md">
      <Link
        to="/"
        className="text-2xl font-bold text-gray-900 dark:text-gray-100"
      >
        RULU
      </Link>
      <Search onSubmit={handleSearch} />
      <div className="flex items-center space-x-4">
        <ToggleTheme onClick={toggleTheme} theme={theme} />

        {isAuth ? (
          <div className="relative">
            <Avatar user={user || {}} onClick={toggleDropdown} />
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-10">
                {links.map(({ text, to, onClick }) => (
                  <Link
                    onClick={(e) => {
                      if (onClick) return onClick(e);
                      setDropdownOpen(false);
                    }}
                    to={to}
                    className="block px-4 py-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    {text}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ) : (
          <Link
            to={"/auth/login"}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
          >
            Войти
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
