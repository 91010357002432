import { PayPalButtons } from "@paypal/react-paypal-js";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectUser, setUser } from "features/auth/slice/authSlice";
import {
  useCaptureOrderMutation,
  useCreateOrderMutation,
} from "features/balance/balanceApi";
import {
  useCreateDepositMutation,
  useCreateTelegramConfirmationMutation,
  useLinkWalletMutation,
} from "features/telegram-wallet/telegramWalletApi";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BalanceTopUp: React.FC = () => {
  const [amount, setAmount] = useState<string>("");
  const [createOrder, { error: createError }] = useCreateOrderMutation();
  const [captureOrder, { error: captureError }] = useCaptureOrderMutation();
  const [createDeposit] = useCreateDepositMutation();
  const [createTelegramConfirmation] = useCreateTelegramConfirmationMutation();
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("TON");
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [method, setMethod] = useState<string>("paypal");
  const [linkWallet] = useLinkWalletMutation();

  const handleCreateOrder = async () => {
    if (!amount || parseFloat(amount) <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    setIsLoading(true);

    if (method === "paypal") {
      try {
        const response = await createOrder({ amount }).unwrap();
        setOrderId(response.id);
        toast.success("Order created successfully! Proceed to PayPal payment.");
      } catch (error) {
        console.error("Error creating PayPal order:", error);
        toast.error("Failed to create PayPal order. Try again.");
      }
    } else if (method === "telegram") {
      if (!user?.telegram_id) {
        const { message } = await createTelegramConfirmation().unwrap();
        handleTelegramLink(message);
        setIsLoading(false);
        return;
      }

      if (!user?.telegram_wallet) {
        if (!walletAddress) {
          toast.error("Please enter your Telegram wallet address.");
          setIsLoading(false);
          return;
        }

        try {
          await linkWallet({ wallet_address: walletAddress }).unwrap();
          toast.success("Telegram wallet linked successfully!");

          const updatedUser = { ...user, telegram_wallet: walletAddress };
          dispatch(setUser(updatedUser));
          localStorage.setItem("user", JSON.stringify(updatedUser));
        } catch (error) {
          console.error("Error linking Telegram wallet:", error);
          toast.error("Error with Telegram wallet linking.");
          setIsLoading(false);
          return;
        }
      }

      try {
        const { data, message } = await createDeposit({
          amount,
          currency,
        }).unwrap();
        console.log(data);
        toast.success(message);
      } catch (error) {
        console.error("Error creating Telegram deposit request:", error);
        toast.error("Error with Telegram deposit request.");
      }
    }
    setIsLoading(false);
  };

  const handleTelegramLink = (message: string) => {
    toast.info(
      <div>
        <p>
          <strong>{message}</strong>
        </p>
        <p>
          <a
            href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Перейти
          </a>
        </p>
        <p className="mt-2 text-gray-600 dark:text-gray-300">
          После того как вы подтвердите свою учетную запись в Telegram,
          пожалуйста, перезагрузите страницу.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none"
        >
          Перезагрузить страницу
        </button>
      </div>,
      {
        autoClose: false,
        position: "top-center",
      }
    );
  };

  const handleApprove = async () => {
    if (!orderId || !user) return;

    try {
      await captureOrder({ orderId }).unwrap();
      toast.success("Balance successfully topped up!");

      const newBalance = (
        parseFloat(user.balance) + parseFloat(amount)
      ).toFixed(2);

      const updatedUser = { ...user, balance: newBalance };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      dispatch(setUser(updatedUser));

      setAmount("");
      setOrderId(null);
    } catch (error: any) {
      console.error("Error capturing order:", error);
      toast.error(
        `Failed to capture order: ${error?.data?.error || "Unknown error"}`
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 min-h-[81vh] bg-gray-50 dark:bg-gray-900 transition duration-300">
      <h2 className="text-4xl font-bold mb-6 text-gray-800 dark:text-gray-200 drop-shadow-lg">
        Пополнить баланс
      </h2>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-[50%]">
        <input
          type="number"
          placeholder="Введите сумму в долларах США"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="p-4 border border-gray-300 dark:border-gray-600 rounded-lg w-full mb-3 text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 shadow-md"
        />
        <div className="flex justify-between mb-4">
          <label>
            <input
              type="radio"
              value="paypal"
              checked={method === "paypal"}
              onChange={() => setMethod("paypal")}
            />
            PayPal
          </label>
          <label>
            <input
              type="radio"
              value="telegram"
              checked={method === "telegram"}
              onChange={() => setMethod("telegram")}
            />
            Telegram Wallet
          </label>
        </div>

        {method === "telegram" && (
          <div className="mb-4">
            {user?.telegram_wallet ? (
              <p className="text-gray-800 dark:text-gray-200">
                Кошелек привязан : {user.telegram_wallet}
              </p>
            ) : (
              <>
                <label
                  htmlFor="walletAddress"
                  className="text-gray-800 dark:text-gray-200"
                >
                  Адрес кошелька Telegram
                </label>
                <input
                  id="walletAddress"
                  type="text"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  placeholder="Enter your Telegram wallet address"
                  className="p-2 border border-gray-300 dark:border-gray-600 rounded-lg w-full mt-1 text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
                />
              </>
            )}
            <label
              htmlFor="currency"
              className="text-gray-800 dark:text-gray-200"
            >
              Выберите валюту
            </label>
            <select
              id="currency"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className="p-2 border border-gray-300 dark:border-gray-600 rounded-lg w-full mt-1 text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"
            >
              <option value="TON">TON</option>
              <option value="USD">USD</option>
              <option value="BTC">BTC</option>
            </select>
          </div>
        )}

        <button
          onClick={handleCreateOrder}
          disabled={isLoading}
          className={`bg-blue-600 text-white py-2 px-4 rounded-lg w-full mt-4 ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? "Обработка..." : "Пополнить"}
        </button>
        {orderId && (
          <div className="mt-6 w-full">
            <PayPalButtons
              createOrder={async () => {
                return orderId;
              }}
              onApprove={handleApprove}
              onError={() => {
                toast.error("ошибка с оплатой через PayPal.");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceTopUp;
