import { useAppSelector } from "app/store/hooks";
import { useGetImagesQuery } from "entities/image/imagesApi";
import ImageItem from "entities/image/ui/ImageItem";
import { useShowProfileQuery } from "entities/user/api/profileApi";
import { selectUser } from "features/auth/slice/authSlice";
import React from "react";
import { useParams } from "react-router-dom";

type Props = {};

const ProfilePage = (props: Props) => {
  const params = useParams();
  const userId = params.user_id && +params.user_id;
  const myId = useAppSelector(selectUser)?.id || -1;
  const user = useAppSelector(selectUser);

  // Получаем данные профиля пользователя
  const {
    data: userProfile,
    isLoading: loadingProfile,
    error: profileError,
  } = useShowProfileQuery(userId || -1);

  // Получаем данные изображений пользователя
  const {
    data: userImages,
    isLoading: loadingImages,
    error: imagesError,
  } = useGetImagesQuery(
    { user_id: userId || -1 },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // Обработка состояний загрузки и ошибок
  if (loadingProfile || loadingImages) {
    return <div className="text-center text-gray-500">Загрузка...</div>;
  }

  if (profileError || imagesError) {
    return <div className="text-red-500 text-center">Ошибка загрузки данных</div>;
  }

  return (
    <div className="profile-page container mx-auto p-6 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div className="flex items-center mb-6">
        {userProfile?.avatar ? (
          <img
            src={userProfile.avatar}
            alt={`${userProfile.name}'s avatar`}
            className="w-32 h-32 rounded-full border-4 border-gray-300 dark:border-gray-600 shadow-lg"
          />
        ) : (
          <div className="w-32 h-32 flex items-center justify-center rounded-full bg-gray-300 dark:bg-gray-600 text-white text-3xl font-bold shadow-lg">
            {userProfile?.name.charAt(0)}
          </div>
        )}
        <div className="ml-4">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
            {userProfile?.name}
          </h1>
          {user && (user.role === "admin" || user.role === "moderator") && (
            <>
              <p className="text-gray-700 dark:text-gray-300">
                Электронная почта:{" "}
                <span className="font-semibold">{userProfile?.email}</span>
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                Баланс:{" "}
                <span className="font-semibold">${userProfile?.balance}</span>
              </p>
            </>
          )}
          <p className="text-gray-700 dark:text-gray-300">
            Очки активности:{" "}
            <span className="font-semibold">
              {userProfile?.activity_points}
            </span>
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Ранг: <span className="font-semibold">{userProfile?.rank}</span>
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Прогресс:{" "}
            <span className="font-semibold">{userProfile?.progress}%</span>
          </p>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mt-4">
        Достижения
      </h2>
      <ul className="list-disc ml-6 text-gray-700 dark:text-gray-300">
        {userProfile?.achievements &&
          userProfile?.achievements.map((achievement) => (
            <li key={achievement.user_id}>
              <strong className="font-semibold">{achievement.title}</strong>:{" "}
              {achievement.description}
            </li>
          ))}
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mt-4">
        Изображения пользователя
      </h2>
      {userImages && userImages.length > 0 ? (
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {userImages.map((image) => (
            <ImageItem
              key={image.id}
              {...image}
              is_disputed={
                user && image.disputed_purchases
                  ? image.disputed_purchases.some((p) => p.user_id === user.id)
                  : false
              }
              is_canceled={
                user && image.canceled_purchases
                  ? image.canceled_purchases.some((p) => p.user_id === user.id)
                  : false
              }
              is_pending={
                user && image.pending_purchases
                  ? image.pending_purchases.some((p) => p.user_id === user.id)
                  : false
              }
              is_purchased={
                user && image.completed_purchases
                  ? image.completed_purchases.some((p) => p.user_id === user.id)
                  : false
              }
              is_liked={
                userProfile
                  ? image.likes.filter((l) => l.user_id === myId)[0]
                  : false
              }
              is_disliked={
                userProfile
                  ? image.dislikes.filter((l) => l.user_id === myId)[0]
                  : false
              }
            />
          ))}
        </ul>
      ) : (
        <p className="text-gray-700 dark:text-gray-300 mt-4">
          Изображения не найдены.
        </p>
      )}
    </div>
  );
};

export default ProfilePage;
