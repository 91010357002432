import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  CreatePurchaseReviewBody,
  UpdatePurchaseReviewBody,
} from "entities/purchase-review/purchaseReviewType";

const baseUrl = `${process.env.REACT_APP_API}/purchases-review`; // Замените на ваш базовый URL

export const purchaseReviewApi = createApi({
  reducerPath: "purchase-review",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createReview: builder.mutation<void, CreatePurchaseReviewBody>({
      query: ({ purchaseId, body }) => ({
        url: `${purchaseId}`,
        method: "POST",
        body,
      }),
    }),
    updateReview: builder.mutation<void, UpdatePurchaseReviewBody>({
      query: ({ purchaseId, body }) => ({
        url: `${purchaseId}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteReview: builder.mutation<void, number>({
      query: (purchaseId) => ({
        url: `${purchaseId}`,
        method: "DELETE",
      }),
    }),
    userReviews: builder.query<void, number>({
      query: (userId) => ({
        url: `${userId}`,
        method: "GET",
      }),
    }),
    purchaseReviews: builder.query<void, number>({
      query: (purchaseId) => ({
        url: `${purchaseId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateReviewMutation,
  useUserReviewsQuery,
  useDeleteReviewMutation,
  usePurchaseReviewsQuery,
  useUpdateReviewMutation,
} = purchaseReviewApi;
