import React from "react";

type Props = {};

const TopDayImageSkeleton = (props: Props) => {
  return (
    <div className="animate-pulse">
      <div className="w-full h-80 bg-gray-300 dark:bg-gray-700 mb-2"></div>

      <div className="flex justify-center space-x-2 mb-2">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className="h-2 w-2 bg-gray-300 dark:bg-gray-700 rounded-full"
            />
          ))}
      </div>

      <div className="absolute bottom-16 left-0 right-0 p-4">
        <div className="flex items-center space-x-4">
          <div className="w-7 h-7 bg-gray-300 dark:bg-gray-700 rounded-full"></div>
          <div className="w-24 h-4 bg-gray-300 dark:bg-gray-700"></div>
        </div>
      </div>

      <div className="flex justify-between p-4 bg-opacity-60 bg-gray-800 dark:bg-gray-200">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className="w-10 h-6 bg-gray-300 dark:bg-gray-700"
            ></div>
          ))}
      </div>
    </div>
  );
};

export default TopDayImageSkeleton;
