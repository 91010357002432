import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { LikeBody, RemoveLike } from "entities/like/likesType";

const baseUrl = `${process.env.REACT_APP_API}/api`; // Замените на ваш базовый URL

export const likesApi = createApi({
  reducerPath: "likesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    like: builder.mutation<void, LikeBody>({
      query: ({ is_like, model_id, model_type }) => ({
        url: `${model_type}/${model_id}/like`,
        method: "POST",
        body: { is_like },
      }),
    }),
    deleteLike: builder.mutation<void, RemoveLike>({
      query: ({ model_type, model_id }) => ({
        url: `${model_type}/${model_id}/like`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useLikeMutation, useDeleteLikeMutation } = likesApi;
