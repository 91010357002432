import { useAppSelector } from "app/store/hooks";
import { useGetImagesQuery } from "entities/image/imagesApi";
import { GetImagesResponse, GetTopImages } from "entities/image/imagesType";
import Filter from "entities/image/ui/Filter";
import ImageItem from "entities/image/ui/ImageItem";
import ImageSkeleton from "entities/image/ui/ImageSkeleton";
import TopDayImage from "entities/image/ui/TopDayImage";
import TopDayImageSkeleton from "entities/image/ui/TopDayImageSkeleton";
import { selectUser } from "features/auth/slice/authSlice";
import { useEffect, useState } from "react";

const ImagesPage = () => {
  const filters = useAppSelector((state) => state.images);
  const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false);
  const [topDayImages, setTopDayImages] = useState<GetTopImages>([]);
  const user = useAppSelector(selectUser);

  // Вызов API для получения рекомендованных изображений
  const { data: recommendedImages, isLoading: isRecommendedLoading } =
    useGetImagesQuery(
      {
        sort: "recommended",
        limit: 6,
        ...filters, // Обновляем запрос при изменении фильтров
      },
      {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
      }
    );

  // Вызов API для получения изображений рекламной ленты
  const { data: adFeedImages, isLoading: isAdFeedLoading } = useGetImagesQuery(
    {
      sort: "ad_feed",
      limit: 6,
      ...filters, // Обновляем запрос при изменении фильтров
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // Вызов API для получения топ-изображений дня
  const { data: fetchedTopDayImages, isLoading: isTopDayLoading } =
    useGetImagesQuery(
      {
        sort: "top_day",
        limit: 3,
        ...filters, // Обновляем запрос при изменении фильтров
      },
      {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
      }
    );

  useEffect(() => {
    if (fetchedTopDayImages) {
      const parsedImages: GetTopImages = fetchedTopDayImages.map((image) => {
        return {
          ...image,
          is_purchased:
            user && image.completed_purchases
              ? image.completed_purchases.some((p) => p.user_id === user.id)
              : false,
          is_pending:
            user && image.pending_purchases
              ? image.pending_purchases.some((p) => p.user_id === user.id)
              : false,
        };
      });
      setTopDayImages(parsedImages);
    }
  }, [fetchedTopDayImages, user]);

  const handleToggleFilters = () => {
    setAreFiltersVisible(!areFiltersVisible);
  };

  const renderImages = (images: GetImagesResponse) => {
    user && images.forEach((i) => console.log(i.completed_purchases));
    return images.map((image) => (
      <ImageItem
        is_disputed={
          user && image.disputed_purchases
            ? image.disputed_purchases.some((p) => p.user_id === user.id)
            : false
        }
        is_canceled={
          user && image.canceled_purchases
            ? image.canceled_purchases.some((p) => p.user_id === user.id)
            : false
        }
        is_pending={
          user && image.pending_purchases
            ? image.pending_purchases.some((p) => p.user_id === user.id)
            : false
        }
        is_purchased={
          user && image.completed_purchases
            ? image.completed_purchases.some((p) => p.user_id === user.id)
            : false
        }
        is_liked={
          user ? image.likes.filter((l) => l.user_id === user.id)[0] : false
        }
        is_disliked={
          user ? image.dislikes.filter((l) => l.user_id === user.id)[0] : false
        }
        {...image}
        key={image.id}
      />
    ));
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <button
        onClick={handleToggleFilters}
        className="mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800"
      >
        {areFiltersVisible ? "Скрыть Фильтры" : "Показать Фильтры"}
      </button>

      {areFiltersVisible && <Filter />}

      <div className="mb-6">
        <h2 className="text-xl font-semibold">Рекомендуемые</h2>
        {isRecommendedLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 min-h-[250px]">
            {[...Array(6)].map((_, index) => (
              <ImageSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 min-h-[250px]">
            {recommendedImages ? (
              renderImages(recommendedImages)
            ) : (
              <p>Нет изображений</p>
            )}
          </div>
        )}
      </div>

      <div className="flex mb-6 space-x-4">
        <div className="flex-1">
          <h2 className="text-xl font-semibold">Топ дня</h2>
          <div className="relative">
            {isTopDayLoading ? (
              <TopDayImageSkeleton />
          ) : (
              <div>
                {topDayImages.length > 0 && (
                  <TopDayImage topDayImages={topDayImages} />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex-1">
          <h2 className="text-xl font-semibold">Обновления</h2>
          {isAdFeedLoading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 h-full">
              {[...Array(6)].map((_, index) => (
                <ImageSkeleton key={index} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 h-full">
              {adFeedImages ? (
                renderImages(adFeedImages)
              ) : (
                <p>Нет изображений</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImagesPage;
