import React from "react";

type Props = {};

const BalanceSuccess = (props: Props) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 dark:bg-green-900 transition duration-300">
      <h1 className="text-3xl font-bold text-green-800 dark:text-green-200">
        Payment Successful!
      </h1>
      <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
        Thank you! Your balance has been successfully topped up.
      </p>
      <a
        href="/balance"
        className="mt-6 text-blue-600 dark:text-blue-400 hover:underline"
      >
        Return to Balance
      </a>
    </div>
  );
};

export default BalanceSuccess;
