import { useAppSelector } from "app/store/hooks";
import { useGetCoursesQuery } from "entities/course/coursesApi";
import { Course } from "entities/course/coursesType";
import CourseItem from "entities/course/ui/CourseItem";
import { selectUser } from "features/auth/slice/authSlice";
import Loading from "shared/ui/Loading";

const CoursesPage = () => {
  const { data, error, isLoading } = useGetCoursesQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const user = useAppSelector(selectUser);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-center text-xl text-red-500">Не удалось получить курсы</div>;
  }

  // Group courses by subsection
  const groupedCourses = data?.reduce((acc, course) => {
    const subsectionName = course.subsection.name;
    if (!acc[subsectionName]) {
      acc[subsectionName] = [];
    }
    acc[subsectionName].push(course);
    return acc;
  }, {} as Record<string, Course[]>);

  return (
    <div className="container mx-auto px-4 py-8 space-y-8 dark:bg-gray-900 dark:text-white">
      <h1 className="text-3xl font-semibold text-center text-gray-900 dark:text-gray-100">
        Видео курсы
      </h1>
      {groupedCourses &&
        Object.entries(groupedCourses).map(([subsectionName, courses]) => (
          <div key={subsectionName}>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300 mt-6">
              {subsectionName}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {courses.map((course) => (
                <CourseItem
                  is_canceled={false}
                  is_disliked={
                    user
                      ? course.dislikes.filter((l) => l.user_id === user.id)[0]
                      : false
                  }
                  is_liked={
                    user
                      ? course.likes.filter((l) => l.user_id === user.id)[0]
                      : false
                  }
                  is_pending={false}
                  is_purchased={false}
                  {...course}
                  key={course.id}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CoursesPage;
