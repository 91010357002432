import { useCreateImageMutation } from "entities/image/imagesApi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {};

const CreateImageForm = (props: Props) => {
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [price, setPrice] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [watermark, setWatermark] = useState<string>("false");
  const [ageRating, setAgeRating] = useState<string>("false");
  const [title, setTitle] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [createImage] = useCreateImageMutation();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (
        selectedFile.size <= 15 * 1024 * 1024 && // Изменено на 15 MB
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(selectedFile.type)
      ) {
        setImage(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));
      } else {
        toast.error(
          "Файл должен быть изображением (jpeg, png, jpg, gif) и размером не более 15MB."
        );
        setImagePreview(null);
      }
    }
  };  

  const validatePrice = (value: string) => {
    return /^\d+(\.\d{1,2})?$/.test(value);
  };

  const validateTags = (value: string) => {
    return value.trim() !== "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!image) {
      toast.warn("Пожалуйста, загрузите изображение.");
      return;
    }

    // Если цена не указана, устанавливаем по умолчанию значение 0
    const finalPrice = price.trim() === "" ? "0" : price;

    if (!validatePrice(finalPrice)) {
      toast.warn(
        "Пожалуйста, укажите правильную цену (например, 10 или 10.99)."
      );
      return;
    }

    if (!validateTags(tags)) {
      toast.warn("Пожалуйста, укажите хотя бы один тег.");
      return;
    }

    setIsSubmitting(true);

    try {
      const resultId = await createImage({
        image,
        price: finalPrice,
        tags,
        watermark,
        is_18_plus: ageRating,
        title,
      }).unwrap();

      toast.success(
        <div>
          <p>Изображение успешно загружено!</p>
          <Link to={`/images/${resultId}`} className="text-blue-500 underline">
            Перейти к изображению
          </Link>
        </div>
      );
    } catch (error: any) {
      const errorMessage =
        error?.data?.message || "Произошла ошибка при загрузке изображения";
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white dark:bg-gray-900 p-8 rounded-lg shadow-md"
      aria-labelledby="form-title"
    >
      <h2
        id="form-title"
        className="text-xl font-semibold text-gray-700 dark:text-gray-200 mb-6 text-center"
      >
        Загрузите изображение
      </h2>

      <div className="mb-6">
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
          htmlFor="image"
        >
          Изображение
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="block w-full px-3 py-2 text-sm text-gray-500 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
          required
          aria-describedby="image-help"
        />
        <small id="image-help" className="text-gray-500 dark:text-gray-400">
          Файл должен быть jpeg, png, jpg или gif и размером до 15MB.
        </small>
        {imagePreview && (
          <div className="mt-4">
            <img
              src={imagePreview}
              alt="Предпросмотр изображения"
              className="w-full h-auto rounded-lg transition-opacity duration-300 ease-in-out"
            />
          </div>
        )}
      </div>

      <div className="mb-6">
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
          htmlFor="price"
        >
          Заголовок
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="block w-full px-3 py-2 text-sm text-gray-500 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
          required
        />
      </div>

      <div className="mb-6">
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
          htmlFor="price"
        >
          Цена
        </label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="block w-full px-3 py-2 text-sm text-gray-500 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
        />
      </div>

      <div className="mb-6">
        <label
          className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
          htmlFor="tags"
        >
          Теги (через запятую)
        </label>
        <input
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          className="block w-full px-3 py-2 text-sm text-gray-500 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300"
          placeholder="Введите теги"
          required
        />
      </div>

      <div className="flex items-center mb-6">
        <input
          type="checkbox"
          checked={watermark === "true"}
          onChange={() => setWatermark(watermark === "true" ? "false" : "true")}
          className="h-5 w-5 text-blue-600 border-gray-300 dark:border-gray-600 rounded focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <label className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
          Водяной знак
        </label>
      </div>

      <div className="flex items-center mb-6">
        <input
          type="checkbox"
          checked={ageRating === "true"}
          onChange={() => setAgeRating(ageRating === "true" ? "false" : "true")}
          className="h-5 w-5 text-blue-600 border-gray-300 dark:border-gray-600 rounded focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
        <label className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
          18+
        </label>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full py-2 px-4 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out ${
          isSubmitting
            ? "bg-gray-500 cursor-not-allowed"
            : "bg-blue-600 hover:bg-blue-700"
        }`}
      >
        {isSubmitting ? "Загрузка..." : "Загрузить изображение"}
      </button>
    </form>
  );
};

export default CreateImageForm;
