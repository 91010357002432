import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PayPalProvider = ({ children }: Props) => {
  return (
    <PayPalScriptProvider options={{ clientId: paypalClientId || "AUHFGRfUxyvZzDDsRM4_33KJhgO4OkP13jzy5vMTIg3oKnMzN1hUv99gO8bA6Bn_IywUM7C6V5WIZOyo" }}>
      {children}
    </PayPalScriptProvider>
  );
};

export default PayPalProvider;
