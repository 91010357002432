import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { CreatePurchaseReviewBody } from "entities/purchase-review/purchaseReviewType";
import {
  CreateSubsectionBody,
  Subsection,
} from "entities/subsection/subsectionsType";

const baseUrl = `${process.env.REACT_APP_API}/subsections`; // Замените на ваш базовый URL

export const subsectionsApi = createApi({
  reducerPath: "subsections-api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSubsections: builder.query<Subsection[], void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
    }),
    createSubsection: builder.mutation<Subsection, CreateSubsectionBody>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetSubsectionsQuery, useCreateSubsectionMutation } =
  subsectionsApi;
