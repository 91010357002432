import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  CreateChatBody,
  CreateChatResponse,
  GetChatsResponse,
  SendMessageBody,
} from "entities/chat/chatTypes";

const baseUrl = `${process.env.REACT_APP_API}/chats`; // Замените на ваш базовый URL

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getChats: builder.query<GetChatsResponse, void>({
      query: () => `/list`,
    }),
    createChat: builder.mutation<CreateChatResponse, CreateChatBody>({
      query: (body) => ({
        url: `/create`,
        method: "POST",
        body,
      }),
    }),
    createMessage: builder.mutation<void, SendMessageBody>({
      query: ({ chatId, body }) => ({
        url: `/${chatId}/messages`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetChatsQuery,
  useCreateChatMutation,
  useCreateMessageMutation,
} = chatApi;
