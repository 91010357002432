import React from "react";
import { FaSearch } from "react-icons/fa";

type Props = {
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
};

const Search = ({ onSubmit }: Props) => {
  return (
    <form onSubmit={onSubmit} className="relative flex-grow mx-4">
      <input
        type="text"
        name="search"
        placeholder="Поиск..."
        className="w-full p-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:text-gray-100"
      />
      <button
        type="submit"
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-lg transition duration-300 ease-in-out"
      >
        <FaSearch />
      </button>
    </form>
  );
};

export default Search;
