import { useGetCategoriesQuery } from "entities/category/categoryApi";
import { useCreateDigitalProductMutation } from "entities/digital-product/digitalProductsApi";
import { CreateDigitalProductBody } from "entities/digital-product/digitalProductTypes";
import React, { useState } from "react";
import { toast } from "react-toastify";

const CreateDigitalProductPage: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [keywords, setKeywords] = useState<string>("");
  const [keywordList, setKeywordList] = useState<string[]>([]); // List of keywords
  const [file, setFile] = useState<File | null>(null);
  const [quantity, setQuantity] = useState<number>(1); // Начальное количество 1
  const [createDigitalProduct, { isLoading, error }] =
    useCreateDigitalProductMutation();
  const { data: categories } = useGetCategoriesQuery();
  const [autoDelivery, setAutoDelivery] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(categoryId);
    if (!file || !categoryId) return;

    const productData: CreateDigitalProductBody = {
      title,
      description,
      price: parseFloat(price),
      categoryId,
      keywords: keywordList, // Using the list of keywords
      file,
      quantity,
      auto_delivery: autoDelivery, // Add the auto_delivery field
    };

    try {
      // Attempt to create the product
      await createDigitalProduct(productData).unwrap();

      // Success notification
      toast.success("Продукт успешно создан!");

      // Clear form state on success
      setTitle("");
      setDescription("");
      setPrice("");
      setCategoryId(null);
      setFile(null);
      setKeywords("");
      setKeywordList([]); // Clear keyword list after successful submit
    } catch (err: any) {
      // Handle error and show error messages
      if (err?.data?.errors) {
        Object.keys(err.data.errors).forEach((field) => {
          err.data.errors[field].forEach((error: string) =>
            toast.error(`${field}: ${error}`)
          );
        });
      } else {
        console.error("Unexpected error:", err);
        toast.error("Произошла непредвиденная ошибка.");
      }
    }
  };

  const handleAddKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && keywords.trim() !== "") {
      setKeywordList((prev) => [...prev, keywords.trim()]);
      setKeywords(""); // Clear input field
    }
  };

  const handleRemoveKeyword = (index: number) => {
    setKeywordList((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="flex justify-center py-8">
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 max-w-lg w-full">
        <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          Создание цифрового продукта
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Product Title */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-title"
            >
              Название продукта
            </label>
            <input
              id="product-title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Введите название продукта"
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>

          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-quantity"
            >
              Количество
            </label>
            <input
              id="product-quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              placeholder="Введите количество продукта"
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
              min={1} // Не меньше 1
              required
            />
          </div>

          {/* Product Description */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-description"
            >
              Описание
            </label>
            <textarea
              id="product-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Введите описание продукта"
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
              rows={4}
              required
            />
          </div>

          {/* Product Price */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-price"
            >
              Цена (USD)
            </label>
            <input
              id="product-price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Введите цену продукта"
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>

          {/* Product Category */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-category"
            >
              Категория
            </label>
            <select
              id="product-category"
              value={categoryId ?? ""}
              onChange={(e) => {
                const value = Number(e.target.value);
                setCategoryId(isNaN(value) ? null : value);
              }}
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
            >
              <option value="">Выберите категорию</option>
              {categories &&
                categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          {/* Keywords */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-keywords"
            >
              Ключевые слова
            </label>
            <div className="flex flex-wrap gap-2 mb-4">
              {keywordList.map((keyword, index) => (
                <span
                  key={index}
                  className="bg-blue-500 text-white px-3 py-1 rounded-full flex items-center"
                >
                  {keyword}
                  <button
                    type="button"
                    onClick={() => handleRemoveKeyword(index)}
                    className="ml-2 text-white"
                  >
                    &#10005;
                  </button>
                </span>
              ))}
            </div>
            <input
              id="product-keywords"
              type="text"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              onKeyDown={handleAddKeyword}
              placeholder="Нажмите Enter для добавления ключевого слова"
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
            />
          </div>

          {/* Product File Upload */}
          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="product-file"
            >
              Файл продукта
            </label>
            <input
              id="product-file"
              type="file"
              onChange={(e) => e.target.files && setFile(e.target.files[0])}
              className="w-full p-2 border border-gray-300 rounded-md dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>

          <div>
            <label
              className="block mb-1 text-gray-700 dark:text-gray-300"
              htmlFor="auto-delivery"
            >
              Автоматическая продажа без подтверждения
            </label>
            <input
              id="auto-delivery"
              type="checkbox"
              checked={autoDelivery}
              onChange={(e) => setAutoDelivery(e.target.checked)}
              className="w-4 h-4 border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
              isLoading && "opacity-50 cursor-not-allowed"
            }`}
            disabled={isLoading}
          >
            {isLoading ? "Загрузка..." : "Создать продукт"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateDigitalProductPage;
