import CreateImageForm from "entities/image/ui/CreateImageForm";
import React from "react";

type Props = {};

const CreateImageFormPage = (props: Props) => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 flex items-center justify-center p-4">
      <div className="max-w-lg w-full bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg">  
        <CreateImageForm />
      </div>
    </div>
  );
};

export default CreateImageFormPage;
