import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  ConfirmPurchaseBody,
  GetMyPurchaseByProductParams,
  Purchase,
  PurchaseBody,
  PurchaseResponse,
} from "entities/purchase/purchaseType";
import { GetMyPurchasesResponse } from "entities/user/types";

const baseUrl = `${process.env.REACT_APP_API}/purchase`; // Замените на ваш базовый URL

export const purchaseApi = createApi({
  reducerPath: "purchase",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    purchase: builder.mutation<PurchaseResponse, PurchaseBody>({
      query: ({ productId, productType }) => ({
        url: `/${productId}/${productType}/make`,
        method: "POST",
      }),
    }),
    confirmPurchase: builder.mutation<void, ConfirmPurchaseBody>({
      query: ({ purchaseId, action }) => ({
        url: `/${purchaseId}/confirm`,
        method: "POST",
        body: {
          action,
        },
      }),
    }),
    showPurchase: builder.query<Purchase, number>({
      query: (purchaseId) => ({
        url: `/${purchaseId}`,
      }),
    }),
    initDispute: builder.mutation<void, number>({
      query: (purchaseId) => ({
        url: `${purchaseId}/init-dispute`,
        method: "PATCH",
      }),
    }),
    getMyPurchases: builder.query<GetMyPurchasesResponse, void>({
      query: () => `users/my/purchases`,
    }),
    getMyPurchaseByProduct: builder.query<
      Purchase,
      GetMyPurchaseByProductParams
    >({
      query: ({ productId, productType }) =>
        `product/${productType}/${productId}/my`,
    }),
    getPurchaseByProduct: builder.query<Purchase, GetMyPurchaseByProductParams>(
      {
        query: ({ productId, productType }) =>
          `product/${productType}/${productId}`,
      }
    ),
  }),
});

export const {
  usePurchaseMutation,
    useConfirmPurchaseMutation,
  useShowPurchaseQuery,
  useInitDisputeMutation,
  useGetMyPurchasesQuery,
  useGetMyPurchaseByProductQuery,
  useGetPurchaseByProductQuery,
} = purchaseApi;
