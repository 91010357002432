import { useGetCategoriesQuery } from "entities/category/categoryApi";
import { useGetDigitalProductsQuery } from "entities/digital-product/digitalProductsApi";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "shared/ui/Loading";

const DigitalProductsPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>(""); // For search input
  const [sortField, setSortField] = useState<string>("created_at"); // Default sort field
  const [sortOrder, setSortOrder] = useState<string>("desc"); // Default sort order
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
  const { data: categories } = useGetCategoriesQuery();

  const { data, isLoading, error, isFetching } = useGetDigitalProductsQuery(
    {
      page: currentPage,
      limit: 10,
      search: searchQuery,
      category_id: categoryId,
      sort_field: sortField,
      sort_order: sortOrder,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // Show error message, but retain filters
  if (error) {
    return (
      <div>
        <p className="text-center text-red-500 font-medium">
          Ошибка при получении товаров.
        </p>
      </div>
    );
  }

  if (isLoading) <Loading />;

  return (
    <div className="container mx-auto px-4 py-6 lg:px-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800 dark:text-gray-100">
        Цифровые продукты
      </h1>

      {/* Search and Filters */}
      <div className="flex flex-col lg:flex-row lg:justify-between items-stretch lg:items-center mb-8 gap-4">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Поиск по названию..."
          className="border border-gray-300 dark:border-gray-700 rounded-lg px-4 py-2 flex-1 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Category Filter */}
        <select
          className="border border-gray-300 dark:border-gray-700 rounded-lg px-4 py-2 flex-1 lg:flex-none focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition"
          value={categoryId ?? ""}
          onChange={(e) => {
            const value = Number(e.target.value);
            setCategoryId(isNaN(value) ? undefined : value);
          }}
        >
             <option value="">Все категории</option>
              {categories &&
                categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
        </select>

        {/* Sorting Options */}
        <select
          className="border border-gray-300 dark:border-gray-700 rounded-lg px-4 py-2 flex-1 lg:flex-none focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition"
          value={`${sortField}_${sortOrder}`}
          onChange={(e) => {
            const value = e.target.value;
            const lastIndex = value.lastIndexOf("_");
            const field = value.slice(0, lastIndex);
            const order = value.slice(lastIndex + 1);

            setSortField(field);
            setSortOrder(order);
          }}
        >
          <option value="created_at_desc">Дата: новые</option>
          <option value="created_at_asc">Дата: старые</option>
          <option value="price_asc">Цена: низкая к высокой</option>
          <option value="price_desc">Цена: высокая к низкой</option>
          <option value="title_asc">Название: А-Я</option>
          <option value="title_desc">Название: Я-А</option>
        </select>
      </div>

      {/* Product List */}
      {data?.data.length ? (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {data.data.map((product) => (
              <Link
                to={`/digital-products/${product.id}`}
                key={product.id}
                className="border border-gray-300 dark:border-gray-700 rounded-lg p-4 bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">
                    {product.title}
                  </h2>
                  <p className="text-lg font-medium text-blue-600 dark:text-blue-400">
                    ${product.price}
                  </p>
                </div>
              </Link>
            ))}
          </div>

          {/* Pagination */}
          <div className="mt-8 flex justify-center items-center gap-2">
            {Array.from({ length: data.last_page }).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-4 py-2 border rounded-lg text-sm font-medium transition-colors duration-200 ${
                  currentPage === index + 1
                    ? "bg-blue-600 text-white border-blue-700 hover:bg-blue-700"
                    : "bg-gray-100 text-gray-600 border-gray-300 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-600"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : isFetching ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="border border-gray-300 dark:border-gray-700 rounded-lg p-4 bg-white dark:bg-gray-800 animate-pulse"
            >
              <div className="flex flex-col space-y-4">
                <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded"></div>
                <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded"></div>
                <div className="h-8 bg-gray-300 dark:bg-gray-700 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500 dark:text-gray-300 mt-4">
          Не удалось найти цифровые товары
        </p>
      )}
    </div>
  );
};

export default DigitalProductsPage;
