import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetNotificationQuery,
  useMarkAsReadMutation,
} from "entities/notification/notificationApi";
import { Notification } from "entities/notification/notificationTypes";
import Pagination from "entities/notification/ui/Pagination";
import LoadingSkeleton from "entities/notification/ui/LoadingSkeleton";
import NotificationList from "entities/notification/ui/NotificationList";

const NotificationsPage: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, isLoading, error } = useGetNotificationQuery(
    `?page=${currentPage}`
  );
  const [markAsRead] = useMarkAsReadMutation();

  useEffect(() => {
    if (data) setNotifications(data.data);
  }, [data]);

  const markAsReadHandler = async (id: number) => {
    await markAsRead(id).unwrap();
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, is_read: true } : n))
    );
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh] py-5 bg-gray-100 dark:bg-gray-900">
      <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-lg">
        {isLoading ? (
          <LoadingSkeleton />
        ) : error ? (
          <ErrorMessage />
        ) : notifications.length === 0 ? (
          <EmptyNotification />
        ) : (
          <NotificationList
            notifications={notifications}
            onMarkAsRead={markAsReadHandler}
          />
        )}
        <Pagination
          currentPage={currentPage}
          totalPages={(data && data.last_page) || -1}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

const ErrorMessage = () => (
  <p className="mt-2 text-red-500 dark:text-red-400">
    There was an error loading notifications. Please try again later.
  </p>
);

const EmptyNotification = () => (
  <div className="flex flex-col items-center justify-center h-60">
    <svg
      className="w-16 h-16 text-gray-400 dark:text-gray-500 mb-4"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 2a8 8 0 00-8 8v2a8 8 0 0016 0V10a8 8 0 00-8-8zm0 14a6 6 0 01-6-6v-2a6 6 0 1112 0v2a6 6 0 01-6 6zm0-10a4 4 0 100 8 4 4 0 000-8z" />
    </svg>
    <p className="text-gray-500 dark:text-gray-400 text-lg font-medium">
      No notifications available.
    </p>
  </div>
);

export default NotificationsPage;
