const ImageItemSkeleton = () => {
  return (
    <div className="relative p-4 h-full animate-pulse">
      {/* Placeholder для изображения */}
      <div className="bg-gray-300 dark:bg-gray-600 h-48 w-full rounded-lg"></div>

      {/* Водяной знак */}
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center overflow-hidden bg-white bg-opacity-25 m-2"></div>

      {/* Placeholder для информации о пользователе */}
      <div className="absolute bottom-10 left-0 right-0 p-4 bg-opacity-60 text-white">
        <div className="flex items-center space-x-4 bg-opacity-60 bg-gray-800 py-1">
          <div className="w-7 h-7 bg-gray-400 rounded-full"></div>
          <div className="h-4 bg-gray-400 w-24 rounded"></div>
        </div>
      </div>

      {/* Placeholder для блока активностей */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-opacity-60 bg-gray-800 dark:bg-gray-200 text-white dark:text-black">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="bg-gray-400 w-4 h-4 rounded mr-2"></div>
            <div className="h-4 bg-gray-400 w-6 rounded"></div>
          </div>
          <div className="flex items-center">
            <div className="bg-gray-400 w-4 h-4 rounded mr-2"></div>
            <div className="h-4 bg-gray-400 w-6 rounded"></div>
          </div>
          <div className="flex items-center">
            <div className="bg-gray-400 w-4 h-4 rounded mr-2"></div>
            <div className="h-4 bg-gray-400 w-6 rounded"></div>
          </div>
          <div className="flex items-center">
            <div className="bg-gray-400 w-4 h-4 rounded mr-2"></div>
            <div className="h-4 bg-gray-400 w-6 rounded"></div>
          </div>
          <div className="flex items-center">
            <div className="bg-gray-400 w-4 h-4 rounded mr-2"></div>
            <div className="h-4 bg-gray-400 w-6 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageItemSkeleton;
