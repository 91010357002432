import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { ShowProfileResponse } from "entities/user/types";

// Define a type for the slice state
interface AuthState {
  token: string;
  user: ShowProfileResponse | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  token: localStorage.getItem("authToken") || "",
  user: (() => {
    const user = localStorage.getItem("user");
    try {
      return user ? JSON.parse(user) : null; // Return null if user is not present
    } catch (error) {
      console.error("Failed to parse user from localStorage:", error);
      return null; // Return null on parse error
    }
  })(),
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<ShowProfileResponse | null>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.token = "";
      localStorage.removeItem("authToken");
      state.user = null;
      localStorage.removeItem("user");
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
