import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useShowCourseQuery } from "entities/course/coursesApi";
import {
  useGetMyPurchaseByProductQuery,
  useInitDisputeMutation,
} from "entities/purchase/purchaseApi";
import { selectUser } from "features/auth/slice/authSlice";
import { FiAlertCircle, FiVideo } from "react-icons/fi"; // Импортируем иконку
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "shared/ui/Loading";

type Props = {};

const CoursePage = (props: Props) => {
  const { id } = useParams();
  const { data, error, isLoading } = useShowCourseQuery(Number(id), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [initDispute, { isLoading: isDisputeLoading }] =
    useInitDisputeMutation();

  const handleInitiateDispute = async () => {
    if (
      !myPurchase ||
      (myPurchase.status !== "pending" && myPurchase.status !== "completed")
    ) {
      toast.warn("Доступ к спорам только для активных покупок.");
      return;
    }

    try {
      await initDispute(myPurchase.id).unwrap();
      toast.success("Диспут инициирован. Администрация свяжется с вами.");
    } catch (error) {
      toast.error("Ошибка при инициировании диспута. Попробуйте снова.");
    }
  };
  const me = useSelector(selectUser);
  const {
    data: myPurchase,
    isLoading: myPurchaseLoading,
    error: purchaseError,
  } = useGetMyPurchaseByProductQuery(
    {
      productType: "course",
      productId: Number(id),
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  if (isLoading || myPurchaseLoading) return <Loading />;
  if (error || (purchaseError && me && me.id !== data?.user_id)) {
    return (
      <div className="flex items-center justify-center min-h-[80vh] p-4 bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-400">
        <div className="max-w-3xl text-center p-6">
          <FiAlertCircle className="text-4xl mb-4 mx-auto" />
          <p className="font-semibold text-lg md:text-xl">
            Произошла ошибка на севере. <br />
            Либо видео недоступно ибо вы не покупали данный курс.
          </p>
        </div>
      </div>
    );
  }

  // Check if the user is the author of the course
  const isAuthor = me?.id === data?.user_id;
  console.log(!myPurchase || myPurchase.status !== "completed");

  if (!isAuthor && (!myPurchase || myPurchase.status !== "completed")) {
    return (
      <p className="text-red-500 dark:text-red-400">
        Доступ к курсу только после покупки.
      </p>
    );
  }

  return (
    <div className="container mx-auto p-4 dark:bg-gray-900 dark:text-gray-100">
      <div className="flex justify-between items-center mb-4">
        {myPurchase?.status === "completed" && (
          <button
            onClick={handleInitiateDispute}
            className="mt-2 bg-red-500 text-white p-2 rounded"
          >
            Инициировать диспут
          </button>
        )}
        <h3 className="text-2xl font-bold">Видео курса</h3>
        {data?.user_id === me?.id && (
          <Link
            to={`/courses/${id}/create-video`}
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-600"
          >
            Добавить видео
          </Link>
        )}
      </div>

      {data?.videos?.length === 0 ? (
        <div className="flex justify-center items-center h-64 bg-gray-100 dark:bg-gray-800 rounded-lg shadow p-6">
          <div className="text-center">
            <FiVideo className="text-gray-500 dark:text-gray-400 text-4xl mb-4" />
            <p className="text-xl text-gray-700 dark:text-gray-300 mb-4">
              Пока нет видео для этого курса.
            </p>
            {isAuthor && (
              <p className="text-gray-600 dark:text-gray-400">
                Добавьте видео, чтобы начать.
              </p>
            )}
          </div>
        </div>
      ) : (
        <ul className="space-y-6">
          {data?.videos?.map((video) => (
            <li
              key={video.id}
              className="bg-gray-100 dark:bg-gray-800 p-4 rounded shadow flex items-center"
            >
              <div className="flex-grow">
                <h4 className="text-lg font-semibold flex items-center">
                  {video.title}
                  {video.is_demo && (
                    <span className="ml-2 px-2 py-1 text-xs font-bold text-white bg-yellow-500 rounded-full">
                      Демо
                    </span>
                  )}
                </h4>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  Дата добавления:{" "}
                  {new Date(video.created_at).toLocaleDateString()}
                </p>
              </div>
              <Link
                to={`/videos/${video.id}`}
                className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 ml-4"
              >
                Смотреть видео
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CoursePage;
