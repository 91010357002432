import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import {
  ChatWithName,
  GetChatsResponse,
  GetChatsResponseItem,
} from "entities/chat/chatTypes";
import { useSendMessageMutation } from "features/openrouter/openrouterApi";
import {
  selectActiveChatId,
  selectChats,
  setChats,
} from "features/openrouter/openrouterSlice";
import { SendMessageBodyMessagesItem } from "features/openrouter/openrouterTypes";
import { useEffect, useState } from "react";
import Loading from "shared/ui/Loading";

type Props = {
  data?: GetChatsResponse;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  onClickChat: (chat: GetChatsResponseItem) => void;
  onCreateNewChat: () => void; // add this prop for the new chat action
};

const ListChats = ({
  error,
  isLoading,
  onClickChat,
  onCreateNewChat,
  data,
}: Props) => {
  const [sendMessage] = useSendMessageMutation();
  const activeChatId = useAppSelector(selectActiveChatId);
  const [namesFetched, setNamesFetched] = useState(false);
  const chats = useAppSelector(selectChats);
  const dispatch = useAppDispatch();

  const handleClickChat = (chat: GetChatsResponseItem) => {
    onClickChat(chat);
  };

  useEffect(() => {
    const fetchChatNames = async () => {
      if (!data || namesFetched || !data.length) return;

      try {
        const chatPromises = data.map(async (item) => {
          if (!item.messages || !item.models) return;

          const messages: SendMessageBodyMessagesItem[] = item.messages.map(
            (m) => ({
              content: m.content,
              role: m.is_from_ai ? "assistant" : "user",
            })
          );

          const modelResults = await Promise.all(
            item.models.map(async (model) => {
              const result = await sendMessage({
                model: model.name,
                messages: [
                  ...messages,
                  {
                    role: "user",
                    content:
                      "Generate name for this chat, without quotes and anything, just title",
                  },
                ],
              }).unwrap();

              return { ...item, name: result.choices[0].message.content };
            })
          );

          return modelResults[0];
        });

        const chatNames = await Promise.all(chatPromises);

        const sortedChats = chatNames
          .filter((c): c is ChatWithName => c !== undefined)
          .map((chat) => ({
            ...chat,
            messages: chat.messages
              ? [...chat.messages].sort(
                  (a, b) =>
                    new Date(a.created_at || "").getTime() -
                    new Date(b.created_at || "").getTime()
                )
              : [],
          }));

        dispatch(setChats(sortedChats));
        setNamesFetched(true);
      } catch (error) {
        console.error("Failed to fetch chat names:", error);
      }
    };

    fetchChatNames();
  }, [data, dispatch, namesFetched, sendMessage]);

  const renderErrorMessage = () => {
    if (!error) return null;

    if ("message" in error) {
      return error.message;
    }

    if ("status" in error) {
      if (typeof error.data === "string") {
        return error.data;
      }
      return `Ошибка: ${error.status}`;
    }

    return "Произошла неизвестная ошибка";
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 dark:text-red-400">
        {renderErrorMessage()}
      </div>
    );
  }

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md flex flex-col h-full">
      <div className="sticky top-0 bg-white dark:bg-gray-800 z-10 py-2">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Мои чаты
        </h2>
      </div>
      <div className="overflow-y-auto flex-1 space-y-3">
        {chats && chats.length > 0 ? (
          chats.map((item) => (
            <div
              onClick={() => handleClickChat(item)}
              key={item.id}
              className={`p-3 rounded-md shadow-sm flex justify-between items-center cursor-pointer ${
                activeChatId === item.id
                  ? "bg-blue-100 dark:bg-blue-600 text-white border border-blue-300"
                  : "bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100"
              }`}
            >
              <div className="text-lg font-medium">{item.name}</div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-600 dark:text-gray-300">
            Нет доступных чатов
          </div>
        )}
      </div>
      <button
        onClick={onCreateNewChat}
        className="mt-4 p-3 w-full bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition"
      >
        Создать новый чат
      </button>
    </div>
  );
};

export default ListChats;
