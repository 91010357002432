import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetModelsResponse,
  SendMessageBody,
  SendMessageResponse,
} from "features/openrouter/openrouterTypes";

const baseUrl = "https://openrouter.ai/api/v1"; // Замените на ваш базовый URL
const openrouterKey = process.env.REACT_APP_OPENROUTER_KEY;

export const openrouterApi = createApi({
  reducerPath: "openrouterApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      if (openrouterKey) {
        headers.set("authorization", `Bearer ${openrouterKey}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getModels: builder.query<GetModelsResponse, void>({
      query: () => `/models`,
    }),
    sendMessage: builder.mutation<SendMessageResponse, SendMessageBody>({
      query: (body) => ({
        url: "/chat/completions",
        body: body,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetModelsQuery, useSendMessageMutation } = openrouterApi;
