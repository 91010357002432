import { useAppSelector } from "app/store/hooks";
import { useDownloadImageMutation } from "entities/image/imagesApi";
import { GetImagesResponseItem } from "entities/image/imagesType";
import { useDeleteLikeMutation, useLikeMutation } from "entities/like/likesApi";
import { selectUser } from "features/auth/slice/authSlice";
import { useState } from "react";
import {
  FaComment,
  FaDownload,
  FaShoppingCart,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const filePaths = process.env.REACT_APP_PUBLIC_PATH;

interface Props extends GetImagesResponseItem {
  is_liked: boolean;
  is_disliked: boolean;
  is_purchased: boolean;
  is_pending: boolean;
  is_canceled: boolean;
  is_disputed: boolean;
}

const ImageItem = (image: Props) => {
  const [likeImage] = useLikeMutation();
  const [deleteLikeImage] = useDeleteLikeMutation();
  const [downloadImage] = useDownloadImageMutation();
  const [isLiked, setIsLiked] = useState(image.is_liked);
  const [isDisliked, setIsDisliked] = useState(image.is_disliked);
  const [likesCount, setLikesCount] = useState(image.likes_count);
  const [isPurchased] = useState(image.is_purchased);
  const [dislikesCount, setDislikesCount] = useState(image.dislikes_count);
  const [downloadsCount, setDownloadsCount] = useState(image.downloads_count);
  const me = useAppSelector(selectUser);
  const isAuthor = image.user_id === me?.id;

  const handleLike = async () => {
    try {
      if (isLiked) {
        setLikesCount(likesCount - 1);
        setIsLiked(false);
      } else {
        if (isDisliked) {
          setDislikesCount(dislikesCount - 1);
          setIsDisliked(false);
        }
        setLikesCount(likesCount + 1);
        setIsLiked(true);
      }
      if (isLiked) {
        await deleteLikeImage({
          model_id: image.id,
          model_type: "images",
        });
      } else {
        await likeImage({
          model_id: image.id,
          model_type: "images",
          is_like: true,
        });
      }
    } catch (error) {
      console.error("Ошибка при лайке изображения:", error);
      setLikesCount(isLiked ? likesCount + 1 : likesCount - 1);
      setIsLiked(!isLiked);
    }
  };

  const handleDislike = async () => {
    try {
      if (isDisliked) {
        setDislikesCount(dislikesCount - 1);
        setIsDisliked(false);
      } else {
        if (isLiked) {
          setLikesCount(likesCount - 1);
          setIsLiked(false);
        }
        setDislikesCount(dislikesCount + 1);
        setIsDisliked(true);
      }
      if (isDisliked) {
        await deleteLikeImage({
          model_id: image.id,
          model_type: "images",
        });
      } else {
        await likeImage({
          model_id: image.id,
          model_type: "images",
          is_like: false,
        });
      }
    } catch (error) {
      console.error("Ошибка при дизлайке изображения:", error);
      setDislikesCount(isDisliked ? dislikesCount + 1 : dislikesCount - 1);
      setIsDisliked(!isDisliked);
    }
  };

  const handleDownload = async (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent redirect

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/images/${image.path.replace(
          "images/",
          ""
        )}/file`
      );

      // Check if the response is okay
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const blob = await response.blob();
      const fileExtension = image.path.split(".").pop(); // Extract the file extension
      const fileName = image.path.replace("images/", "") || "downloaded-file";

      // Dynamically set the file extension if it exists
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName}.${fileExtension}`; // Use the correct file extension

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDownloadsCount((prev) => prev + 1); // Update download count

      // Notify server that the image has been downloaded
      await downloadImage(image.id).unwrap();

      toast.success("Изображение успешно скачано!");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Не удалось скачать изображение. Попробуйте еще раз.");
    }
  };

  console.log(image);

  return (
    <div className="relative p-4 h-full">
      {/* Author label */}
      {isAuthor && (
        <div className="absolute top-2 left-2 z-30 bg-blue-600 text-white font-bold py-1 px-2 rounded">
          Ваше изображение
        </div>
      )}

      {image.is_disputed && (
        <div className="absolute top-2 right-2 z-30 bg-purple-600 text-white font-bold py-1 px-2 rounded">
          Диспут
        </div>
      )}

      {/* Purchased label */}
      {isPurchased && (
        <div className="absolute top-2 right-2 z-30 bg-green-600 text-white font-bold py-1 px-2 rounded">
          Куплено
        </div>
      )}

      {image.is_pending && (
        <div className="absolute top-2 left-2 z-30 bg-yellow-500 text-black font-bold py-1 px-2 rounded">
          Ожидает
        </div>
      )}

      {image.is_canceled && (
        <div className="absolute top-2 left-2 z-30 bg-red-600 text-white font-bold py-1 px-2 rounded">
          Отменено
        </div>
      )}

      <Link className="relative z-20" to={`/images/${image.id}`} key={image.id}>
          <img
            src={`${filePaths}${image.path || image.watermark_path}`}
            alt={`${image.id}`}
            className={`h-full w-full object-cover ${
              image.is_18_plus ? "blur-md" : ""
            }`} // Removed blur for author
          />

        {/* Watermark */}
        {!isPurchased && !isAuthor && image.watermark && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center overflow-hidden bg-white bg-opacity-25 m-2"
            style={{
              background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100px" width="100px"><text transform="translate(20, 100) rotate(-30)" fill="rgba(128,128,128, 0.3)" font-size="20">RULU</text></svg>')`,
            }}
          ></div>
        )}
      </Link>

      {/* User Information */}
      <div className="absolute bottom-10 left-0 right-0 p-4 bg-opacity-60 z-20 text-white">
        <Link
          to={`/profiles/${image.user_id}`}
          className="flex items-center space-x-4 bg-opacity-60 bg-gray-800 py-1 p-3"
        >
          {image.user.avatar ? (
            <img
              src={`${image.user.avatar}`}
              alt={image.user.name}
              className="h-7 w-7 object-cover"
            />
          ) : (
            <div className="w-7 h-7 rounded-full bg-gray-500 flex items-center justify-center text-white text-sm font-semibold">
              {image.user.name.charAt(0)}
            </div>
          )}
          <span className="text-sm font-semibold">{image.user.name}</span>
        </Link>
      </div>

      {/* Activity Block */}
      <div className="z-20 absolute bottom-0 left-0 right-0 p-4 bg-opacity-60 bg-gray-800 dark:bg-gray-200 text-white dark:text-black">
        <div className="flex justify-between">
          <div
            onClick={handleLike}
            className={`flex items-center cursor-pointer ${
              isLiked ? "text-blue-500" : ""
            }`}
          >
            <FaThumbsUp className="mr-2" />
            <button>{likesCount}</button>
          </div>
          <div
            onClick={handleDislike}
            className={`flex items-center cursor-pointer ${
              isDisliked ? "text-red-500" : ""
            }`}
          >
            <FaThumbsDown className="mr-2" />
            <button>{dislikesCount}</button>
          </div>
          <Link
            to={`/profiles/${image.user_id}`}
            className="flex items-center cursor-pointer"
          >
            <FaComment className="mr-2" />
            <button>{image.comments_count}</button>
          </Link>
          <div
            onClick={handleDownload}
            className="flex items-center cursor-pointer"
          >
            <FaDownload className="mr-2" />
            <button>{downloadsCount}</button>
          </div>
          <Link
            to={`/images/${image.id}`}
            className={`flex items-center cursor-pointer ${
              isPurchased ? "text-green-500" : ""
            }`}
          >
            <FaShoppingCart className="mr-2" />
            <button
              className={`font-semibold ${isPurchased ? "underline" : ""}`}
            >
              {image.completed_purchases_count}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ImageItem;
