import React from "react";

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  theme: string;
};

const ToggleTheme = ({ onClick, theme }: Props) => {
  return (
    <button
      onClick={onClick}
      className="p-2 bg-gray-200 rounded-lg dark:bg-gray-700 transition duration-300 ease-in-out"
    >
      {theme === "light" ? "🌙" : "☀️"}
    </button>
  );
};

export default ToggleTheme;
