import { Notification } from "entities/notification/notificationTypes";
import { Link } from "react-router-dom";

const NotificationList = ({
  notifications,
  onMarkAsRead,
}: {
  notifications: Notification[];
  onMarkAsRead: (id: number) => void;
}) => (
  <ul className="mt-2">
    {notifications.map((notification) => (
      <li
        key={notification.id}
        onClick={() => onMarkAsRead(notification.id)}
        className={`p-2 rounded-lg mb-2 cursor-pointer ${
          notification.is_read
            ? "bg-gray-100 dark:bg-gray-700"
            : "bg-white dark:bg-gray-900"
        } hover:bg-gray-200 dark:hover:bg-gray-600 transition-all`}
      >
        <h4 className="font-medium text-gray-800 dark:text-gray-300">
          {notification.title}
        </h4>
        <p className="text-gray-600 dark:text-gray-400">
          {notification.message}
        </p>
        {notification.link && (
          <Link
            to={notification.link}
            className="text-blue-500 hover:underline dark:text-blue-300"
          >
            View Details
          </Link>
        )}
      </li>
    ))}
  </ul>
);

export default NotificationList;
