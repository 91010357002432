import ReduxProvider from "app/providers/redux";
import RouterProvider from "app/providers/router";
import "./app/styles/index.css";
import PayPalProvider from "app/providers/paypal";

function App() {
  return (
    <ReduxProvider>
      <PayPalProvider>
        <RouterProvider />
      </PayPalProvider>
    </ReduxProvider>
  );
}

export default App;
