import { useAppDispatch, useAppSelector } from "app/store/hooks";
import {
  selectChatInput,
  setChatInput,
} from "features/openrouter/openrouterSlice";
import React from "react";

type Props = {
  onSend?: React.MouseEventHandler<HTMLButtonElement>;
};

const SendMessageInput = ({ onSend }: Props) => {
  const dispatch = useAppDispatch();
  const inititalChatInput = useAppSelector(selectChatInput);
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setChatInput(e.target.value));
  };

  return (
    <div className="flex items-center">
      <textarea
        className="flex-1 p-2 border rounded-md"
        rows={3}
        placeholder="Введите свое сообщение с помощью Markdown..."
        value={inititalChatInput}
        onChange={handleInputChange}
      />
      <button
        className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md"
        onClick={onSend}
      >
        Отправлять
      </button>
    </div>
  );
};

export default SendMessageInput;
