import React from 'react';
import { Link } from 'react-router-dom';

const InDevelopmentPage = () => {
  return (
    <div className="min-h-[80vh] bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-all relative">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <h1 className="text-4xl font-semibold mb-4">В процессе создания</h1>
        <p className="text-lg mb-6">Эта страница еще в разработке.</p>
        <div className="flex justify-center space-x-4">
          <Link to="/" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
            Обратно на главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InDevelopmentPage;
