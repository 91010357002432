import { useAppSelector } from "app/store/hooks";
import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { selectUser } from "features/auth/slice/authSlice";
import ImageItem from "entities/image/ui/ImageItem";
import { useGetImagesQuery } from "entities/image/imagesApi";
import { useGetMyPurchasesQuery } from "entities/purchase/purchaseApi";
import { useGetMyCoursesQuery } from "entities/course/coursesApi";
import { Course } from "entities/course/coursesType";

const MyProfilePage: React.FC = () => {
  const user = useAppSelector(selectUser);
  const {
    data: dataMyPurchases,
    error: errorMyPurchases,
    isLoading: isLoadingMyPurchases,
  } = useGetMyPurchasesQuery();

  const {
    data: dataMyCourses,
    isLoading: isLoadingMyCourses,
    error: errorMyCourses,
  } = useGetMyCoursesQuery();

  const {
    data: dataMyImages,
    error: errorMyImages,
    isLoading: isLoadingMyImages,
  } = useGetImagesQuery({ user_id: user ? user.id : -1 });

  const imagePurchases = dataMyPurchases?.filter((p) =>
    p.product_type.includes("Image")
  );

  if (!user) {
    return (
      <div className="container mx-auto p-4 dark:bg-gray-800 dark:text-white">
        <h1 className="text-2xl font-bold mb-4">Личный Кабинет</h1>
        <p>Вы не авторизованы. Пожалуйста, войдите в систему.</p>
      </div>
    );
  }

  const avatarContent = user.avatar ? (
    <img
      src={user.avatar}
      alt="User Avatar"
      className="w-20 h-20 rounded-full border-2 border-blue-500 shadow-lg"
    />
  ) : (
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-gray-400 text-white font-bold border-2 border-blue-500 shadow-lg">
      {user.name.charAt(0).toUpperCase()}
    </div>
  );

  const truncateDescription = (
    description: string,
    maxLength: number = 150
  ) => {
    if (description.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const groupedCourses = dataMyCourses?.reduce((acc, course) => {
    const subsectionName = course.subsection.name;
    if (!acc[subsectionName]) {
      acc[subsectionName] = [];
    }
    acc[subsectionName].push(course);
    return acc;
  }, {} as Record<string, Course[]>);

  return (
    <div className="container mx-auto p-6 dark:bg-gray-800 dark:text-white">
      <h1 className="text-3xl font-bold mb-6 text-center">Личный Кабинет</h1>
      <div
        className="relative mb-6 w-full h-48 bg-cover bg-center rounded-lg shadow-md"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center">
          <div className="container mx-auto flex items-center gap-6 p-4">
            {avatarContent}
            <div className="text-white">
              <h2 className="text-3xl font-bold">{user.name}</h2>
              <p className="text-lg">
                Тип пользователя: {user.role || "Стандарт"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Контент с двумя частями */}
      <div className="grid grid-cols-5 gap-6 mb-6">
        {/* Левая часть - 60% */}
        <div className="col-span-3 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2">Основной контент</h3>
          {/* Пример другого достижения */}
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 bg-green-500 text-white flex items-center justify-center rounded-full shadow-lg">
              🌟
            </div>
            <div className="flex-1">
              <h4 className="text-md font-bold text-gray-900 dark:text-gray-100">
                Активность
              </h4>
              <div className="w-full bg-gray-300 rounded-full h-4 dark:bg-gray-600">
                <div
                  className="bg-green-500 h-4 rounded-full"
                  style={{
                    width: `${Math.min(user.activity_points / 100, 100)}%`,
                  }}
                ></div>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                Ваши активные баллы: {user.activity_points}.
              </p>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Обо мне</h3>
            <p className="text-gray-500 italic dark:text-gray-400">
              Пользователь о себе ничего не написал.
            </p>
          </div>

          {/* Разделы "Подарки" и "Ачивки" */}
          <div className="grid grid-cols-2 gap-6 mt-6">
            {/* Раздел "Подарки" */}
            <div className="bg-gray-200 dark:bg-gray-600 p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4 text-center">
                Подарки
              </h3>
              <div className="flex justify-center items-center gap-4">
                <img
                  src="/toy1.jpg"
                  alt="Подарок 1"
                  className="rounded-lg shadow-md w-20 h-20 object-cover"
                />
                <img
                  src="/toy1.jpg"
                  alt="Подарок 2"
                  className="rounded-lg shadow-md w-20 h-20 object-cover"
                />
              </div>
            </div>

            {/* Раздел "Ачивки" */}
            <div className="bg-gray-200 dark:bg-gray-600 p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4 text-center">Ачивки</h3>
              <div className="flex justify-center items-center gap-4">
                <img
                  src="/toy1.jpg"
                  alt="Ачивка 1"
                  className="rounded-lg shadow-md w-20 h-20 object-cover"
                />
                <img
                  src="/toy1.jpg"
                  alt="Ачивка 2"
                  className="rounded-lg shadow-md w-20 h-20 object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Правая часть - 40% */}
        <div className="col-span-2 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4 text-center">
            Дополнительный контент
          </h3>

          <div className="space-y-6">
            <div className="mt-4 grid gap-6 grid-cols-1 sm:grid-cols-2">
              <Link
                to="/dashboard/purchases-history"
                className="flex items-center justify-center text-center bg-blue-500 text-white font-medium py-3 px-4 rounded-md hover:bg-blue-600 transition duration-200 text-sm md:text-base whitespace-nowrap"
                style={{ minWidth: "160px" }}
              >
                История покупок
              </Link>
              <Link
                to="/dashboard/create-image"
                className="flex items-center justify-center text-center bg-blue-500 text-white font-medium py-3 px-4 rounded-md hover:bg-blue-600 transition duration-200 text-sm md:text-base whitespace-nowrap"
                style={{ minWidth: "160px" }}
              >
                🖼️ Создать картинку
              </Link>
              <Link
                to="/dashboard/create-digitalproduct"
                className="flex items-center justify-center text-center bg-blue-500 text-white font-medium py-3 px-4 rounded-md hover:bg-blue-600 transition duration-200 text-sm md:text-base whitespace-nowrap"
                style={{ minWidth: "160px" }}
              >
                📦 Цифровой продукт
              </Link>
              <Link
                to="/dashboard/create-course"
                className="flex items-center justify-center text-center bg-blue-500 text-white font-medium py-3 px-4 rounded-md hover:bg-blue-600 transition duration-200 text-sm md:text-base whitespace-nowrap"
                style={{ minWidth: "160px" }}
              >
                📚 Создать курс
              </Link>
            </div>

            {/* New Section for Recommendations (Рекламный блок) */}
            <div className="mt-6 bg-gray-200 dark:bg-gray-600 p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4 text-center">
                Рекомендации
              </h3>
              <div className="flex flex-col items-center">
                <p className="text-center text-gray-700 dark:text-gray-300 mb-4">
                  Рекомендуем вам ознакомиться с нашими новыми курсами и
                  продуктами!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfilePage;
