import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { GetNotificationsResponse } from "entities/notification/notificationTypes";

const baseUrl = `${process.env.REACT_APP_API}/notifications`; // Замените на ваш базовый URL

export const notificationsApi = createApi({
  reducerPath: "notifications",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNotification: builder.query<
      GetNotificationsResponse,
      string | undefined
    >({
      query: (pageUrl) => (pageUrl ? pageUrl : ``), // если `pageUrl` не указан, используется корневой URL
    }),
    markAsRead: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}/read`,
        method: "PATCH",
      }),
    }),
  }),
});

export const { useGetNotificationQuery, useMarkAsReadMutation } =
  notificationsApi;
