import { Course } from "entities/course/coursesType";
import { useDeleteLikeMutation, useLikeMutation } from "entities/like/likesApi";
import { useState } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { Link } from "react-router-dom";

interface Props extends Course {
  is_liked: boolean;
  is_disliked: boolean;
  is_purchased: boolean;
  is_pending: boolean;
  is_canceled: boolean;
}

const CourseItem = (course: Props) => {
  const [likesCount, setLikesCount] = useState(course.likes.length);
  const [dislikesCount, setDislikesCount] = useState(course.dislikes.length);
  const [isLiked, setIsLiked] = useState(course.is_liked);
  const [isDisliked, setIsDisliked] = useState(course.is_disliked);
  const [purchaseStatus, setPurchaseStatus] = useState<string | null>(null); // Track purchase status
  const truncateDescription = (
    description: string,
    maxLength: number = 150
  ) => {
    if (description.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const [like] = useLikeMutation();
  const [deleteLike] = useDeleteLikeMutation();
  const handleLike = async () => {
    try {
      if (isLiked) {
        setLikesCount(likesCount - 1);
        setIsLiked(false);
      } else {
        if (isDisliked) {
          setDislikesCount(dislikesCount - 1);
          setIsDisliked(false);
        }
        setLikesCount(likesCount + 1);
        setIsLiked(true);
      }
      if (isLiked) {
        await deleteLike({
          model_id: course.id,
          model_type: "course",
        });
      } else {
        await like({
          model_id: course.id,
          model_type: "course",
          is_like: true,
        });
      }
    } catch (error) {
      console.error("Ошибка при лайке курса:", error);
      setLikesCount(isLiked ? likesCount + 1 : likesCount - 1);
      setIsLiked(!isLiked);
    }
  };

  const handleDislike = async () => {
    try {
      if (isDisliked) {
        setDislikesCount(dislikesCount - 1);
        setIsDisliked(false);
      } else {
        if (isLiked) {
          setLikesCount(likesCount - 1);
          setIsLiked(false);
        }
        setDislikesCount(dislikesCount + 1);
        setIsDisliked(true);
      }
      if (isDisliked) {
        await deleteLike({
          model_id: course.id,
          model_type: "course",
        });
      } else {
        await like({
          model_id: course.id,
          model_type: "course",
          is_like: false,
        });
      }
    } catch (error) {
      console.error("Ошибка при дизлайке изображения:", error);
      setDislikesCount(isDisliked ? dislikesCount + 1 : dislikesCount - 1);
      setIsDisliked(!isDisliked);
    }
  };

  return (
    <div
      key={course.id}
      className="border p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out transform dark:bg-gray-800 dark:border-gray-700"
    >
      <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
        {course.title}
      </h3>
      <p className="text-gray-700 dark:text-gray-300 mt-2">
        {truncateDescription(course.description)}
      </p>
      <p className="text-gray-800 dark:text-gray-200 mt-2 font-semibold">
        Цена: ${course.price}
      </p>
      <p className="text-gray-500 dark:text-gray-400 mt-1">
        Рейтинг: {course.rating} / 5
      </p>
      <p className="text-gray-600 dark:text-gray-400 mt-1">
        Автор:{" "}
        <Link
          to={"/profiles/" + course.user.id}
          className="text-blue-600 dark:text-blue-400 font-semibold underline hover:text-blue-800 dark:hover:text-blue-300"
        >
          {course.user.name}
        </Link>
      </p>
      <div className="flex items-center space-x-4 mt-4 text-gray-700 dark:text-gray-400">
        <span
          className="flex items-center space-x-1 cursor-pointer"
          onClick={handleLike}
        >
          <FaThumbsUp className="text-green-500" />
          <span>{likesCount}</span>
        </span>
        <span
          className="flex items-center space-x-1 cursor-pointer"
          onClick={handleDislike}
        >
          <FaThumbsDown className="text-red-500" />
          <span>{dislikesCount}</span>
        </span>
      </div>
      <Link
        to={"/courses/" + course.id + "/demo"}
        className="inline-block mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none dark:bg-blue-700 dark:hover:bg-blue-600"
      >
        Демо
      </Link>
    </div>
  );
};

export default CourseItem;
