import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { CreateCommentBody, CreateCommentResponse, GetCommentResponse } from "entities/comment/commentTypes";

const baseUrl = `${process.env.REACT_APP_API}/images`; // Замените на ваш базовый URL

export const commentsApi = createApi({
  reducerPath: "commentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getComments: builder.query<GetCommentResponse, number>({
      query: (imageId) => ({
        url: `/${imageId}/comments`,
        method: "GET",
      }),
    }),
    createComment: builder.mutation<CreateCommentResponse, CreateCommentBody>({
      query: ({ imageId, content }) => ({
        url: `/${imageId}/comments`,
        method: "POST",
        body: {
          content,
        },
      }),
    }),
  }),
});

export const { useGetCommentsQuery, useCreateCommentMutation } = commentsApi;
