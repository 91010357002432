import BalanceCancel from "pages/BalanceCancel";
import BalanceSuccess from "pages/BalanceSuccess";
import BalancePage from "pages/BalanceTopPage";
import CoursePage from "pages/CoursePage";
import CreateCoursePage from "pages/CreateCoursePage";
import CreateDigitalProductPage from "pages/CreateDigitalProductPage";
import CreateImageFormPage from "pages/Dashboard/CreateImageFormPage";
import MyProfilePage from "pages/Dashboard/MyProfilePage";
import DigitalProductDetailPage from "pages/DigitalProductDetailPage";
import DigitalProductsPage from "pages/DigitalProductsPage";
import EmailVerifiedPage from "pages/EmailVerifiedPage";
import ImageDetailPage from "pages/ImageDetailPage";
import ImagesPage from "pages/ImagesPage";
import LoginPage from "pages/LoginPage";
import NotFoundPage from "pages/NotFoundPage";
import NotificationsPage from "pages/NotificationsPage";
import OpenRouterPage from "pages/OpenRouterPage";
import ProfilePage from "pages/ProfilePage";
import PurchaseDecisionPage from "pages/PurchaseDecisionPage ";
import RegisterPage from "pages/RegisterPage";
import CoursesPage from "pages/CoursesPage";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "shared/ui/ProtectedRoute";
import Layout from "widgets/layout";
import CreateVideoCoursePage from "pages/CreateVideoCoursePage";
import VideoPage from "pages/VideoPage";
import DemoVideos from "pages/DemoVideos";
import AssumptionsStatsPage from "pages/AssumptionsStatsPage";
import PurchasesHistoryPage from "pages/PurchasesHistoryPage";
import InDevelopmentPage from "pages/InDevelopmentPage";
import EditImageForm from "entities/image/ui/EditImageForm";

export const router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <OpenRouterPage />,
      },
      {
        path: "images",
        children: [
          {
            path: "",
            element: <ImagesPage />
          },
          {
            path: "edit/:id",
            element: <EditImageForm/>
          }
        ],
      },
      {
        path: "auth",
        children: [
          {
            path: "login",
            element: <ProtectedRoute isAuthRoute children={<LoginPage />} />,
          },
        ],
      },
      {
        path: "auth",
        children: [
          {
            path: "register",
            element: <ProtectedRoute isAuthRoute children={<RegisterPage />} />,
          },
        ],
      },
      {
        path: "email-verified",
        element: (
          <ProtectedRoute isAuthRoute children={<EmailVerifiedPage />} />
        ),
      },
      {
        path: "dashboard",
        children: [
          {
            path: "",
            element: <ProtectedRoute children={<MyProfilePage />} />,
          },
          {
            element: (
              <ProtectedRoute
                children={<ProtectedRoute children={<CreateImageFormPage />} />}
              />
            ),
            path: "create-image",
          },
          {
            element: <ProtectedRoute children={<CreateCoursePage />} />,
            path: "create-course",
          },
        ],
      },
      {
        path: "profiles/:user_id",
        element: <ProfilePage />,
      },
      {
        path: "images/:id",
        element: <ImageDetailPage />,
      },
      {
        path: "balance",
        element: <ProtectedRoute children={<BalancePage />} />,
      },
      {
        path: "balance/success",
        element: <ProtectedRoute children={<BalanceSuccess />} />,
      },
      {
        path: "balance/cancel",
        element: <ProtectedRoute children={<BalanceCancel />} />,
      },
      {
        path: "notifications",
        element: <ProtectedRoute children={<NotificationsPage />} />,
      },
      {
        path: "purchases/:purchaseId/confirm",
        element: <ProtectedRoute children={<PurchaseDecisionPage />} />,
      },
      {
        path: "digital-products",
        element: <DigitalProductsPage />,
      },
      {
        path: "dashboard/create-digitalproduct",
        element: <ProtectedRoute children={<CreateDigitalProductPage />} />,
      },
      {
        path: "digital-products/:id",
        element: <DigitalProductDetailPage />,
      },
      {
        path: "courses",
        element: <CoursesPage />,
      },
      {
        path: "courses/:id/demo",
        element: <DemoVideos />,
      },
      {
        path: "courses/:id",
        element: <CoursePage />,
      },
      {
        path: "videos/:id",
        element: <VideoPage />,
      },
      {
        path: "courses/:id/create-video",
        element: <ProtectedRoute children={<CreateVideoCoursePage />} />,
      },
      {
        path: "assumptions-stats",
        element: <ProtectedRoute children={<AssumptionsStatsPage />} />,
      },
      {
        path: "dashboard/purchases-history",
        element: <ProtectedRoute children={<PurchasesHistoryPage />} />,
      },
      {
        path: "points-store",
        element: <InDevelopmentPage/>
      },
      {
        path: "my-cards",
        element: <InDevelopmentPage/>
      },
      {
        path: "settings",
        element: <InDevelopmentPage/>
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);
