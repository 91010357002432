import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  LoginBody,
  LoginResponse,
  RegisterBody,
  RegisterResponse,
} from "features/auth/types/authTypes";

const baseUrl = `${process.env.REACT_APP_API}/auth`; // Замените на ваш базовый URL

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: `login`,
        body,
        method: "POST",
      }),
    }),
    register: builder.mutation<RegisterResponse, RegisterBody>({
      query: (body) => ({
        url: `register`,
        body,
        method: "POST",
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApi;
