import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

// Определяем тип для состояния среза
interface ImagesState {
  tagFilter?: string;
  is18Plus?: boolean;
  priceMin?: number;
  priceMax?: number;
  allowDuplicates: boolean; // Новое поле для разрешения дублирования тегов
  caseSensitive: boolean; // Новое поле для учета регистра
}

// Определяем начальное состояние с учетом новых полей
const initialState: ImagesState = {
  allowDuplicates: false, // Устанавливаем по умолчанию
  caseSensitive: false, // Устанавливаем по умолчанию
};

export const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setTagFilter: (state, action: PayloadAction<string>) => {
      state.tagFilter = action.payload;
    },
    setIs18Plus: (state, action: PayloadAction<boolean | undefined>) => {
      state.is18Plus = action.payload;
    },
    setPriceMin: (state, action: PayloadAction<number>) => {
      state.priceMin = action.payload;
    },
    setPriceMax: (state, action: PayloadAction<number>) => {
      state.priceMax = action.payload;
    },
    setAllowDuplicates: (state, action: PayloadAction<boolean>) => {
      state.allowDuplicates = action.payload; // Обработчик для нового поля
    },
    setCaseSensitive: (state, action: PayloadAction<boolean>) => {
      state.caseSensitive = action.payload; // Обработчик для нового поля
    },
  },
});

export const {
  setTagFilter,
  setIs18Plus,
  setPriceMax,
  setPriceMin,
  setAllowDuplicates, // Экспортируем новое действие
  setCaseSensitive, // Экспортируем новое действие
} = imagesSlice.actions;

// Селекторы для новых полей
export const selectTagFilter = (state: RootState) => state.images.tagFilter;
export const selectIs18Plus = (state: RootState) => state.images.is18Plus;
export const selectPriceMin = (state: RootState) => state.images.priceMin;
export const selectPriceMax = (state: RootState) => state.images.priceMax;
export const selectAllowDuplicates = (state: RootState) =>
  state.images.allowDuplicates; // Новый селектор
export const selectCaseSensitive = (state: RootState) =>
  state.images.caseSensitive; // Новый селектор

export default imagesSlice.reducer;
