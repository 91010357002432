import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  HandleConfirmationTokenResponse,
  LinkWaleltBody,
  LinkWalletResponse,
  TelegramWalletCreateDepositBody,
  TelegramWalletCreateDepositResponse,
} from "features/telegram-wallet/telegramWalletTypes";

const baseUrl = `${process.env.REACT_APP_API}/telegram-wallet`; // Замените на ваш базовый URL

// Создаем API для работы с балансом
export const telegramWalletApi = createApi({
  reducerPath: "telegramWalletApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createDeposit: builder.mutation<
      TelegramWalletCreateDepositResponse,
      TelegramWalletCreateDepositBody
    >({
      query: (data) => ({
        url: "/create-deposit-request",
        method: "POST",
        body: data,
      }),
    }),
    createTelegramConfirmation: builder.mutation<
      HandleConfirmationTokenResponse,
      void
    >({
      query: () => ({
        url: `create-telegram-confirmation`,
        method: "POST",
      }),
    }),
    linkWallet: builder.mutation<LinkWalletResponse, LinkWaleltBody>({
      query: (body) => ({
        url: `link-wallet`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateDepositMutation,
  useCreateTelegramConfirmationMutation,
  useLinkWalletMutation,
} = telegramWalletApi;
