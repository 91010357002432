import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { selectModelIds, setModelIds } from "features/chat/chatSlice";
import { useGetModelsQuery } from "features/openrouter/openrouterApi";

type Props = {
  onClose: () => void;
};

const ModelSelectorModal: React.FC<Props> = ({ onClose }) => {
  const { data: models } = useGetModelsQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useAppDispatch();
  const modelIds = useAppSelector(selectModelIds);

  // Filter models based on search term
  const filteredModels =
    models &&
    models.data.filter(
      (model) =>
        model.name &&
        model.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleAddModel = (modelId: string) => {
    if (!modelIds.includes(modelId)) {
      dispatch(setModelIds([...modelIds, modelId]));
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-200">
          Выберите модель
        </h2>

        {/* Search input */}
        <input
          type="text"
          placeholder="Search models..."
          className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* List of models */}
        <ul className="max-h-60 overflow-y-auto">
          {filteredModels &&
            filteredModels.map((model) => (
              <li
                key={model.id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
                onClick={() => handleAddModel(model.id || "")}
              >
                {model.name}
              </li>
            ))}
        </ul>

        {/* Close button */}
        <button
          onClick={onClose}
          className="mt-4 w-full px-3 py-2 bg-blue-500 text-white rounded-lg"
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default ModelSelectorModal;
