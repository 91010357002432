const LoadingSkeleton = () => (
  <ul className="mt-2">
    {Array.from({ length: 5 }).map((_, index) => (
      <li
        key={index}
        className="p-2 mb-2 rounded-lg bg-gray-200 dark:bg-gray-700 animate-pulse"
      >
        <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-2"></div>
        <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-full"></div>
      </li>
    ))}
  </ul>
);

export default LoadingSkeleton;