import React from "react";

type Props = {};

const BalanceCancel = (props: Props) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50 dark:bg-red-900 transition duration-300">
      <h1 className="text-3xl font-bold text-red-800 dark:text-red-200">
        Payment Cancelled
      </h1>
      <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
        Your payment has been cancelled. You can try again.
      </p>
      <a
        href="/balance"
        className="mt-6 text-blue-600 dark:text-blue-400 hover:underline"
      >
        Return to Balance
      </a>
    </div>
  );
};

export default BalanceCancel;
