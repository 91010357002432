import { useAppSelector } from "app/store/hooks";
import { selectMessages } from "features/openrouter/openrouterSlice";
import React from "react";
import ReactMarkdown from "react-markdown";

const Messaages = () => {
  const messages = useAppSelector(selectMessages);

  return (
    <div className="flex-1 overflow-y-auto text-gray-800 dark:text-gray-100">
      {messages.map((message, index) => (
        <div
          key={index}
          className="mb-4 p-4 border-b border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-sm"
        >
          <ReactMarkdown>{message + ""}</ReactMarkdown>
        </div>
      ))}
    </div>
  );
};

export default Messaages;
