import { router } from "app/router";
import React from "react";
import { RouterProvider as Provider } from "react-router-dom";

type Props = {};

const RouterProvider = (props: Props) => {
  return <Provider router={router} />;
};

export default RouterProvider;
