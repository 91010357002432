import { useStreamVideoQuery } from "entities/video-course/videoc-course-api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function VideoPlayer({ video }: any) {
  const [videoUrl, setVideoUrl] = useState("");
  const { data, error, isLoading } = useStreamVideoQuery(
    video.file_path.replace("videos/", "")
  );

  useEffect(() => {
    if (error) {
      toast.error("Error loading video. Please try again.");
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const fetchVideo = async () => {
        const url = URL.createObjectURL(data); // Create URL from blob
        setVideoUrl(url);

        // Clean up blob URL on component unmount
        return () => URL.revokeObjectURL(url);
      };

      fetchVideo();
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-2xl mb-8">
        <div className="animate-pulse w-full h-full min-h-[350px] bg-gray-300"></div>
      </div>
    );
  }

  return (
    <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-2xl mb-8">
      {videoUrl && (
        <video className="w-full h-full object-cover" controls src={videoUrl} />
      )}
    </div>
  );
}

export default VideoPlayer;
