import { useLocation, Link } from "react-router-dom";

const EmailVerifiedPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");

  return (
    <div className="flex flex-col items-center justify-center h-[80vh] bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-8">
      {message && (
        <div className="max-w-md w-full bg-green-100 text-green-800 p-6 rounded-lg shadow-lg text-center mb-8">
          <p className="text-lg font-semibold">{message}</p>
        </div>
      )}

      <div className="text-center">
        <p className="text-gray-500 dark:text-gray-400 text-base">
          Теперь вы можете{" "}
          <Link
            to="/auth/login"
            className="text-blue-500 hover:underline dark:text-blue-400 font-medium"
          >
            войти в систему
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default EmailVerifiedPage;
