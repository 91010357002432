import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import {
  CreateImageBody,
  GetImageDetail,
  GetImagesQueryParams,
  GetImagesResponse,
  Image,
  UpdateImageRequest,
} from "entities/image/imagesType";

const baseUrl = `${process.env.REACT_APP_API}/images`; // Замените на ваш базовый URL

export const imagesApi = createApi({
  reducerPath: "imagesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getImages: builder.query<GetImagesResponse, GetImagesQueryParams>({
      query: (params) => ({
        url: "/list",
        method: "GET",
        params,
      }),
    }),
    createImage: builder.mutation<number, CreateImageBody>({
      query: (body) => {
        const formData = new FormData();
        formData.append("image", body.image);
        formData.append("is_18_plus", String(body.is_18_plus)); // Ensure boolean is converted to string
        formData.append("price", String(body.price)); // Convert price to string if it's a number
        formData.append("tags", body.tags); // Assuming tags is an array, join it into a string
        formData.append("watermark", String(body.watermark)); // Convert watermark boolean to string
        formData.append("title", body.title);

        return {
          url: `/create`, // Provide the correct URL for your image upload endpoint
          body: formData,
          method: "POST",
        };
      },
    }),
    getImageDetail: builder.query<GetImageDetail, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
    }),
    downloadImage: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}/download`,
        method: "GET",
      }),
    }),
    destroyImage: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
    }),
    updateImage: builder.mutation<
      Image,
      { id: number; data: UpdateImageRequest }
    >({
      query: ({ id, data }) => {
        const formData = new FormData();
        if (data.title) formData.append('title', data.title);
        if (data.price !== undefined) formData.append('price', data.price.toString());
        if (data.is_18_plus !== undefined) formData.append('is_18_plus', data.is_18_plus.toString());
        if (data.watermark !== undefined) formData.append('watermark', data.watermark.toString());
        if (data.image) formData.append('image', data.image);

        return {
          url: `/images/${id}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetImagesQuery,
  useCreateImageMutation,
  useGetImageDetailQuery,
  useDownloadImageMutation,
  useDestroyImageMutation,
  useUpdateImageMutation,
} = imagesApi;
