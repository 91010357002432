import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { Course, CreateCourseBody } from "entities/course/coursesType";

const baseUrl = `${process.env.REACT_APP_API}/courses`; // Замените на ваш базовый URL

export const coursesApi = createApi({
  reducerPath: "coursesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCourses: builder.query<Course[], void>({
      query: () => ({
        url: ``,
      }),
    }),
    showCourse: builder.query<Course, number>({
      query: (id) => ({
        url: `${id}`,
      }),
    }),
    getMyCourses: builder.query<Course[], void>({
      query: () => ({
        url: `users/my`,
      }),
    }),
    createCourse: builder.mutation<Course, CreateCourseBody>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useCreateCourseMutation,
  useGetMyCoursesQuery,
  useShowCourseQuery,
} = coursesApi;
