// src/components/ImageUpdateForm.tsx
import React, { useState } from "react";
import { useGetImageDetailQuery, useUpdateImageMutation } from "../imagesApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ImageUpdateForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: image,
    isLoading: imageLoading,
    error: imageError,
  } = useGetImageDetailQuery(Number(id), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [formData, setFormData] = useState({
    title: image?.title || "",
    price: image?.price || 0,
    is_18_plus: image?.is_18_plus || false,
    watermark: image?.watermark || false,
    image: null as File | null,
  });

  const [updateImage, { isLoading, isError, isSuccess, error }] =
    useUpdateImageMutation();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const isCheckbox = type === "checkbox";
    setFormData((prev) => ({
      ...prev,
      [name]: isCheckbox
        ? (e as React.ChangeEvent<HTMLInputElement>).target.checked
        : value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData((prev) => ({
        ...prev,
        image: e.target.files && e.target.files[0],
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!image) {
      toast.error("некоректные данные");
      return;
    }

    const { image: file, price, ...rest } = formData;

    const parsedPrice = typeof price === "string" ? parseFloat(price) : price;
    if (isNaN(parsedPrice)) {
      toast.error("Invalid price value.");
      return;
    }

    const validData = {
      ...rest,
      price: parsedPrice,
      image: file || undefined, // Ensure `null` is not passed
    };

    await updateImage({ id: image.id, data: validData });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 p-4 bg-white shadow-md rounded-md"
    >
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div>
        <label
          htmlFor="price"
          className="block text-sm font-medium text-gray-700"
        >
          Price
        </label>
        <input
          type="number"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="flex items-center space-x-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="is_18_plus"
            checked={formData.is_18_plus}
            onChange={handleChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm">18+</span>
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            name="watermark"
            checked={formData.watermark}
            onChange={handleChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm">Watermark</span>
        </label>
      </div>
      <div>
        <label
          htmlFor="image"
          className="block text-sm font-medium text-gray-700"
        >
          Upload Image
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleFileChange}
          className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 disabled:bg-gray-300"
      >
        {isLoading ? "Updating..." : "Update Image"}
      </button>
      {isError && (
        <p className="text-red-500 text-sm mt-2">
          Error: {(error as any)?.error}
        </p>
      )}
      {isSuccess && (
        <p className="text-green-500 text-sm mt-2">
          Image updated successfully!
        </p>
      )}
    </form>
  );
};

export default ImageUpdateForm;
