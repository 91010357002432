import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

// Define a type for the slice state
interface AuthState {
  modelIds: string[];
}

// Define the initial state using that type
const initialState: AuthState = {
  modelIds: ["openai/chatgpt-4o-latest"],
};

export const chatSlice = createSlice({
  name: "chat",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setModelIds: (state, action: PayloadAction<string[]>) => {
      state.modelIds = action.payload;
    },
    setModelIdsInitial: (state) => {
      state.modelIds = initialState.modelIds;
    },
    removeModelId: (state, action: PayloadAction<string>) => {
      state.modelIds = state.modelIds.filter((id) => id !== action.payload);
    },
  },
});

export const { setModelIds, setModelIdsInitial, removeModelId } =
  chatSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectModelIds = (state: RootState) => state.chat.modelIds;

export default chatSlice.reducer;
