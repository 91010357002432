import { useAppDispatch, useAppSelector } from "app/store/hooks";
import {
  useCreateChatMutation,
  useCreateMessageMutation,
  useGetChatsQuery,
} from "entities/chat/chatApi";
import { GetChatsResponseItem } from "entities/chat/chatTypes";
import ListChats from "entities/chat/ui/ListChats";
import Messaages from "entities/message/ui/Messaages";
import SendMessageInput from "entities/message/ui/SendMessageInput";
import ModelSelectorModal from "entities/model/ui/ModelSelectorModal";
import { selectToken } from "features/auth/slice/authSlice";
import {
  removeModelId,
  selectModelIds,
  setModelIds,
  setModelIdsInitial,
} from "features/chat/chatSlice";
import { useSendMessageMutation } from "features/openrouter/openrouterApi";
import {
  appendMessages,
  appendSendedMessages,
  selectChats,
  setActiveChatId,
  setChats,
  setIsChatCreated,
  setMessages,
  setSendedMessages,
} from "features/openrouter/openrouterSlice";
import { SendMessageBodyMessagesItem } from "features/openrouter/openrouterTypes";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

type Props = {};

const OpenRouterPage = (props: Props) => {
  const getChats = useGetChatsQuery();
  const [sendMessage] = useSendMessageMutation();
  const [createChat] = useCreateChatMutation();
  const [createMessage] = useCreateMessageMutation();
  const modelIds = useAppSelector(selectModelIds);
  const { chatInput, isChatCreated, sendedMessages, messages, activeChatId } =
    useAppSelector((state) => state.openrouter);
  const dispatch = useAppDispatch();
  const chats = useAppSelector(selectChats);
  const [isModelSelectorOpen, setModelSelectorOpen] = useState(false);
  const isAuthenticated = useAppSelector(selectToken);

  const openModelSelector = () => setModelSelectorOpen(true);
  const closeModelSelector = () => setModelSelectorOpen(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  // Skeleton component
  const Skeleton = () => (
    <div className="animate-pulse space-y-4">
      <div className="bg-gray-300 dark:bg-gray-700 h-4 rounded w-3/4"></div>
      <div className="bg-gray-300 dark:bg-gray-700 h-4 rounded w-1/2"></div>
      <div className="bg-gray-300 dark:bg-gray-700 h-4 rounded w-full"></div>
    </div>
  );

  const onChooseChat = (chat: GetChatsResponseItem) => {
    if (!chat.id || !chat.models || !chat.messages) return;

    dispatch(setActiveChatId(chat.id));
    dispatch(setModelIds(chat.models.map((m) => m.name)));
    dispatch(setIsChatCreated(true));

    dispatch(
      setMessages(
        chat.messages.map((m) => {
          if (m.is_from_ai) return `${m.content}`;
          else return `Вы: ${m.content}`;
        })
      )
    );
    dispatch(
      setSendedMessages(
        chat.messages.map((m) => ({
          content: m.content,
          role: m.is_from_ai ? "assistant" : "user",
        }))
      )
    );
  };

  const createChatHandler = useCallback(
    async (newMessages: SendMessageBodyMessagesItem[]) => {
      setIsLoading(true); // Start loading
      try {
        if (!isAuthenticated) {
          // If not authenticated, just update state and exit
          dispatch(
            setMessages([
              ...newMessages.map((m) =>
                m.role === "user" ? `Вы: ${m.content}` : m.content
              ),
            ])
          );
          dispatch(setSendedMessages([...newMessages]));
          setIsLoading(false); // End loading
          return;
        }
        // Создание нового чата
        const newChat = await createChat({
          model_ids: modelIds,
        }).unwrap();

        if (newChat.id) {
          dispatch(setActiveChatId(newChat.id));
          dispatch(setIsChatCreated(true));

          // Получение ответов от каждой модели
          const modelResponses = await Promise.all(
            modelIds.map(async (modelId) => {
              const result = await sendMessage({
                model: modelId,
                messages: [
                  ...newMessages,
                  {
                    role: "user",
                    content:
                      "Generate name for this chat, without quotes and anything, just title",
                  },
                ],
              }).unwrap();

              return {
                modelId,
                content: result.choices[0].message.content,
              };
            })
          );

          // Обновление информации о моделях для чата
          const models = modelIds.map((id) => ({
            id: +id,
            created_at: new Date(),
            name: `${id}`,
            pivot: { chat_id: newChat.id, model_id: +id },
          }));

          // Создание объекта чата в состоянии с первыми сообщениями
          dispatch(
            setChats([
              ...chats,
              {
                ...newChat,
                name: modelResponses[0].content,
                messages: newMessages.map((m) => ({
                  is_from_ai: m.role !== "user",
                  content: `Вы: ${m.content}`, // Формат для пользователя
                })),
                models,
              },
            ])
          );

          // Сохранение сообщений пользователя в базе данных
          for (const { content, role } of newMessages) {
            await createMessage({
              chatId: newChat.id,
              body: { content: `Вы: ${content}`, is_from_ai: role !== "user" },
            }).unwrap();
          }

          // Сохранение сообщений от ботов в базе данных
          for (const { content, modelId } of modelResponses) {
            await createMessage({
              chatId: newChat.id,
              body: {
                content: `Модель ${modelId}: ${content}`,
                is_from_ai: true,
              },
            }).unwrap();
          }

          // Обновление состояния сообщений с учётом ответов от ботов
          dispatch(
            setMessages([
              ...newMessages.map((m) =>
                m.role === "user" ? `Вы: ${m.content}` : m.content
              ),
              ...modelResponses.map(
                (res) => `Модель ${res.modelId}: ${res.content}`
              ),
            ])
          );
          dispatch(
            setSendedMessages([
              ...newMessages,
              ...modelResponses.map((res) => ({
                content: res.content,
                role: "assistant",
              })),
            ])
          );
        }
      } catch (error) {
        alert("Ошибка при создании чата: " + error);
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [
      createChat,
      createMessage,
      modelIds,
      dispatch,
      chats,
      sendMessage,
      isAuthenticated,
    ]
  );

  const handleSendMessage = async () => {
    if (!modelIds || !chatInput) return;

    const userMessage: SendMessageBodyMessagesItem = {
      content: chatInput,
      role: "user",
    };

    const userMessageText = `Вы: ${chatInput}`;
    setIsLoading(true); // Start loading

    try {
      if (!isChatCreated && isAuthenticated) {
        await createChatHandler([...sendedMessages, userMessage]);
      } else {
        // Append the user message to the chat area
        dispatch(appendMessages([userMessageText]));
        dispatch(appendSendedMessages([{ role: "user", content: chatInput }]));

        if (isAuthenticated) {
          await createMessage({
            chatId: activeChatId,
            body: { content: chatInput, is_from_ai: false },
          }).unwrap();
        }

        // Iterate over models to get responses
        for (const modelId of modelIds) {
          const response = await sendMessage({
            model: modelId,
            messages: [...sendedMessages, userMessage],
          }).unwrap();

          const botMessageContent = response.choices[0].message.content;
          const botMessageText = `Модель ${modelId}: ${botMessageContent}`;

          // Append the bot's response to the messages
          dispatch(appendMessages([botMessageText]));
          dispatch(
            appendSendedMessages([
              { role: "assistant", content: botMessageContent },
            ])
          );

          // Save bot message to the database
          if (isAuthenticated) {
            await createMessage({
              chatId: activeChatId,
              body: { content: botMessageContent, is_from_ai: true },
            }).unwrap();
          }
        }
      }
    } catch (error) {
      alert("Failed to send message: " + error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // New function to create a new chat and reset state
  const createNewChatHandler = () => {
    // Reset messages and sended messages
    dispatch(setMessages([]));
    dispatch(setSendedMessages([]));
    dispatch(setModelIdsInitial());
    dispatch(setIsChatCreated(false));
    dispatch(setActiveChatId(-1)); // Reset active chat ID
  };

  const onRemoveModel = (id: string) => {
    dispatch(removeModelId(id));
  };

  return (
    <div className="flex h-[calc(81vh)] overflow-hidden">
      {/* Left sidebar with models and chats */}
      <aside className="w-1/5 h-full flex flex-col overflow-hidden">
        <div className="h-5/6 overflow-y-auto">
          {!isAuthenticated ? (
            <div className="p-4 text-center">
              <p className="text-gray-500 dark:text-gray-400">
                История чата не сохраняется.
              </p>
              <Link
                to="/auth/login"
                className="mt-2 inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Войдите, чтобы сохранить чаты
              </Link>
            </div>
          ) : (
            <ListChats
              onCreateNewChat={createNewChatHandler}
              onClickChat={onChooseChat}
              {...getChats}
            />
          )}
        </div>
      </aside>

      {/* Main content with chat and ad sidebar */}
      <div className="flex flex-1">
        {/* Chat messages block with reduced width */}
        <main className="w-3/4 flex flex-col p-4 h-full overflow-hidden">
          <div className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 min-h-[81vh] flex flex-col">
            {/* Header with model buttons */}
            <div className="flex items-center space-x-4 p-4 border-b border-gray-300 dark:border-gray-700">
              {modelIds.map((id) => (
                <div key={id} className="flex items-center space-x-2">
                  <button className="px-4 py-2 bg-gray-200 dark:bg-gray-800 rounded-lg text-gray-800 dark:text-gray-200">
                    {id}
                  </button>
                  <button
                    onClick={() => onRemoveModel(id)}
                    className="text-gray-500 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400"
                  >
                    ✕
                  </button>
                </div>
              ))}
              <button
                onClick={openModelSelector}
                className="ml-auto px-2 py-2 bg-gray-200 dark:bg-gray-800 rounded-full text-gray-800 dark:text-gray-200"
              >
                +
              </button>
            </div>
            {isModelSelectorOpen && (
              <ModelSelectorModal onClose={closeModelSelector} />
            )}

            {/* Main chat area */}
            <div className="flex-grow flex flex-col justify-between p-4 overflow-auto">
              <Messaages />
              {isLoading && (
                <div className="p-4">
                  <Skeleton />
                </div>
              )}
              <SendMessageInput onSend={handleSendMessage} />
            </div>
          </div>
        </main>

        {/* Ad sidebar */}
        <aside className="w-1/4 bg-gray-100 dark:bg-gray-800 p-4">
          <h2 className="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-300">
            Спонсорские объявления
          </h2>
          <div className="text-gray-600 dark:text-gray-400">
            {/* Replace with actual ad content */}
            <p>Здесь размещается рекламный контент.</p>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default OpenRouterPage;
